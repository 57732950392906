import React, { useState } from "react";
import { Box, TextField, Checkbox, Button, Typography, FormControlLabel, FormControl, FormLabel } from "@mui/material";
import { CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme, styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { registerUser } from "../../../redux/slices/authSlice";
import AddressSearch from "./AddressSearchMobile";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const NeumorphicTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#e0e0e0",
  borderRadius: "8px",
  boxShadow: "inset 4px 4px 8px #bebebe, inset -4px -4px 8px #ffffff",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#ccc",
    },
    "&:hover fieldset": {
      borderColor: "#aaa",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#aaa",
    },
  },
  "& .MuiInputLabel-root": {
    color: "#333",
    fontSize: "1.4rem",
  },
  "& .MuiInputBase-input": {
    color: "#333",
    fontSize: "1.4rem",
  },
}));

function RegisterIndividualMobile({ onBackToUserTypeSelection }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState("");

  const [activeStep, setActiveStep] = useState(0);
  const [addressCount, setAddressCount] = useState("1");
  const [formData, setFormData] = useState({
    individual: { fullName: "", surname: "", idNumber: "", email: "", cell: "" },
    representative: { hasRepresentative: false, fullName: "", surname: "", idNumber: "", email: "", cell: "" },
    addresses: [{ complex_name: "", complex_address1: "", complex_address2: "", complex_postalcode: "", complex_province: "" }],
    communication: { landlord: true, representative: false },
    documents: { municipalBill: [], idPassport: [], representativeIdPassport: [], bankLetter: [] },
    termsAccepted: false,
  });
  const [errors, setErrors] = useState({});

  const handleNext = () => setActiveStep((prev) => prev + 1);

  
  const handleBack = () => setActiveStep((prev) => prev - 1);

  const handleChange = (section, field, value) => {
    setFormData((prev) => ({ ...prev, [section]: { ...prev[section], [field]: value } }));
  };

  const handleAddressChange = (index, field, value) => {
    const newAddresses = [...formData.addresses];
    newAddresses[index] = { ...newAddresses[index], [field]: value };
    setFormData((prev) => ({ ...prev, addresses: newAddresses }));
  };

  const handleFileChange = (e, docType) => {
    const files = e.target.files;
    setFormData((prev) => ({
      ...prev,
      documents: { ...prev.documents, [docType]: files },
    }));
    setErrors((prev) => ({ ...prev, documents: { ...prev.documents, [docType]: "" } }));
  };

  //const totalSteps = (formData.representative.hasRepresentative ? 5 : 4) + (parseInt(addressCount, 10) || 1);

  const numAddresses = parseInt(addressCount, 10) || 1;
  const hasRep = formData.representative.hasRepresentative;

  // Calculate step boundaries:
  const individualStep = 0;
  const representativeStep = hasRep ? 1 : -1; // if no rep, ignore
  const startAddressStep = hasRep ? 2 : 1;
  const endAddressStep = startAddressStep + numAddresses - 1;
  const commStep = endAddressStep + 1;
  const docsStep = commStep + 1;
  const termsStep = docsStep + 1;
  const calculatedTotalSteps = termsStep + 1; // steps are zero-indexed

  // Update totalSteps used in the UI:
  const totalSteps = calculatedTotalSteps;

  const validateStep = (step) => {
    const newErrors = {};
    let valid = true;

    if (step === individualStep) {
      // Validate individual details
      if (!formData.individual.fullName) {
        valid = false;
        newErrors.individual = { ...(newErrors.individual || {}), fullName: "Full Name is required" };
      }
      if (!formData.individual.surname) {
        valid = false;
        newErrors.individual = { ...(newErrors.individual || {}), surname: "Surname is required" };
      }
      if (!formData.individual.idNumber) {
        valid = false;
        newErrors.individual = { ...(newErrors.individual || {}), idNumber: "ID Number is required" };
      }
      if (!formData.individual.email) {
        valid = false;
        newErrors.individual = { ...(newErrors.individual || {}), email: "Email is required" };
      }
      if (!formData.individual.cell) {
        valid = false;
        newErrors.individual = { ...(newErrors.individual || {}), cell: "Cell is required" };
      }
    } else if (step === representativeStep) {
      // Validate representative details if applicable
      if (!formData.representative.fullName) {
        valid = false;
        newErrors.representative = { ...(newErrors.representative || {}), fullName: "Representative Full Name is required" };
      }
      if (!formData.representative.surname) {
        valid = false;
        newErrors.representative = { ...(newErrors.representative || {}), surname: "Representative Surname is required" };
      }
      if (!formData.representative.idNumber) {
        valid = false;
        newErrors.representative = { ...(newErrors.representative || {}), idNumber: "Representative ID Number is required" };
      }
      if (!formData.representative.email) {
        valid = false;
        newErrors.representative = { ...(newErrors.representative || {}), email: "Representative Email is required" };
      }
      if (!formData.representative.cell) {
        valid = false;
        newErrors.representative = { ...(newErrors.representative || {}), cell: "Representative Cell is required" };
      }
    } else if (step >= startAddressStep && step <= endAddressStep) {
      // Validate address step fields
      const addressIndex = step - startAddressStep;
      const address = formData.addresses[addressIndex] || {};
      if (!address.complex_address1) {
        valid = false;
        newErrors.addresses = newErrors.addresses || [];
        newErrors.addresses[addressIndex] = {
          ...(newErrors.addresses[addressIndex] || {}),
          complex_address1: "Address is required",
        };
      }
      if (!address.complex_name) {
        valid = false;
        newErrors.addresses = newErrors.addresses || [];
        newErrors.addresses[addressIndex] = {
          ...(newErrors.addresses[addressIndex] || {}),
          complex_name: "Complex Name is required",
        };
      }
    } else if (step === commStep) {
      // At least one of the communication checkboxes must be true.
      if (!formData.communication.landlord && !formData.communication.representative) {
        valid = false;
        newErrors.communication = "At least one communication method must be selected";
      }
    } else if (step === docsStep) {
      // Validate required document uploads
      if (!formData.documents.municipalBill || formData.documents.municipalBill.length === 0) {
        valid = false;
        newErrors.documents = { ...(newErrors.documents || {}), municipalBill: "Municipal Bill is required" };
      }
      if (!formData.documents.idPassport || formData.documents.idPassport.length === 0) {
        valid = false;
        newErrors.documents = { ...(newErrors.documents || {}), idPassport: "ID/Passport is required" };
      }
      if (formData.representative.hasRepresentative) {
        if (!formData.documents.representativeIdPassport || formData.documents.representativeIdPassport.length === 0) {
          valid = false;
          newErrors.documents = {
            ...(newErrors.documents || {}),
            representativeIdPassport: "Representative ID/Passport is required",
          };
        }
      }
      if (!formData.documents.bankLetter || formData.documents.bankLetter.length === 0) {
        valid = false;
        newErrors.documents = { ...(newErrors.documents || {}), bankLetter: "Bank Letter is required" };
      }
    } else if (step === termsStep) {
      if (!formData.termsAccepted) {
        valid = false;
        newErrors.terms = "You must accept the terms and conditions";
      }
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateStep(activeStep)) {
      if (activeStep === totalSteps - 1) {
        setIsSubmitting(true);
        const submissionData = new FormData();
        submissionData.append("individual_fullName", formData.individual.fullName);
        submissionData.append("individual_surname", formData.individual.surname);
        submissionData.append("individual_idNumber", formData.individual.idNumber);
        submissionData.append("individual_email", formData.individual.email);
        submissionData.append("individual_cell", formData.individual.cell);
        submissionData.append("representative_hasRepresentative", formData.representative.hasRepresentative);
        if (formData.representative.hasRepresentative) {
          submissionData.append("representative_fullName", formData.representative.fullName);
          submissionData.append("representative_surname", formData.representative.surname);
          submissionData.append("representative_idNumber", formData.representative.idNumber);
          submissionData.append("representative_email", formData.representative.email);
          submissionData.append("representative_cell", formData.representative.cell);
        }
        formData.addresses.forEach((address, index) => {
          submissionData.append(`addresses_${index}_complex_name`, address.complex_name);
          submissionData.append(`addresses_${index}_complex_address1`, address.complex_address1);
          submissionData.append(`addresses_${index}_complex_address2`, address.complex_address2);
          submissionData.append(`addresses_${index}_complex_postalcode`, address.complex_postalcode);
          submissionData.append(`addresses_${index}_complex_province`, address.complex_province);
        });
        submissionData.append("communication_landlord", formData.communication.landlord);
        submissionData.append("communication_representative", formData.communication.representative);
        Array.from(formData.documents.municipalBill).forEach((file) => {
          submissionData.append("documents.municipalBill", file);
        });
        Array.from(formData.documents.idPassport).forEach((file) => {
          submissionData.append("documents.idPassport", file);
        });
        Array.from(formData.documents.representativeIdPassport).forEach((file) => {
          submissionData.append("documents.representativeIdPassport", file);
        });
        Array.from(formData.documents.bankLetter).forEach((file) => {
          submissionData.append("documents.bankLetter", file);
        });
        submissionData.append("termsAccepted", formData.termsAccepted);

        dispatch(registerUser(submissionData))
          .unwrap()
          .then(() => {
            setIsSubmitting(false);
            setSubmissionStatus("success");
          })
          .catch((error) => {
            console.error("Registration failed:", error);
            setIsSubmitting(false);
            setSubmissionStatus("failure");
          });
      } else {
        handleNext();
      }
    }
  };

  let content;
  if (activeStep === individualStep) {
    content = (
      <Box component="form" onSubmit={handleSubmit}>
        <TextField label="Full Name" value={formData.individual.fullName} onChange={(e) => handleChange("individual", "fullName", e.target.value)} fullWidth margin="dense" error={!!errors.individual?.fullName} helperText={errors.individual?.fullName} InputProps={{ sx: { fontSize: "1.4rem", color: "#333" } }} InputLabelProps={{ sx: { fontSize: "1.4rem", color: "#333" } }} />
        <TextField label="Surname" value={formData.individual.surname} onChange={(e) => handleChange("individual", "surname", e.target.value)} fullWidth margin="dense" error={!!errors.individual?.surname} helperText={errors.individual?.surname} InputProps={{ sx: { fontSize: "1.4rem", color: "#333" } }} InputLabelProps={{ sx: { fontSize: "1.4rem", color: "#333" } }} />
        <TextField label="ID Number" value={formData.individual.idNumber} onChange={(e) => handleChange("individual", "idNumber", e.target.value)} fullWidth margin="dense" error={!!errors.individual?.idNumber} helperText={errors.individual?.idNumber} InputProps={{ sx: { fontSize: "1.4rem", color: "#333" } }} InputLabelProps={{ sx: { fontSize: "1.4rem", color: "#333" } }} />
        <TextField label="Email" value={formData.individual.email} onChange={(e) => handleChange("individual", "email", e.target.value)} fullWidth margin="dense" error={!!errors.individual?.email} helperText={errors.individual?.email} InputProps={{ sx: { fontSize: "1.4rem", color: "#333" } }} InputLabelProps={{ sx: { fontSize: "1.4rem", color: "#333" } }} />
        <TextField label="Cell" value={formData.individual.cell} onChange={(e) => handleChange("individual", "cell", e.target.value)} fullWidth margin="dense" error={!!errors.individual?.cell} helperText={errors.individual?.cell} InputProps={{ sx: { fontSize: "1.4rem", color: "#333" } }} InputLabelProps={{ sx: { fontSize: "1.4rem", color: "#333" } }} />
        <FormControlLabel control={<Checkbox checked={formData.representative.hasRepresentative} onChange={(e) => handleChange("representative", "hasRepresentative", e.target.checked)} sx={{ "& .MuiSvgIcon-root": { fontSize: "1.8rem", color: "#333" } }} />} label={<Typography sx={{ fontSize: "1.4rem", color: "#333" }}>Has Representative</Typography>} />
        <Box display="flex" justifyContent="space-between" mt={1}>
          <Button onClick={onBackToUserTypeSelection} variant="outlined" sx={{ fontSize: "1.2rem", color: "#333", borderColor: "#ccc" }}>
            Back
          </Button>
          <Button type="submit" variant="contained" sx={{ fontSize: "1.2rem", backgroundColor: "#e0e0e0", color: "#333", boxShadow: "4px 4px 8px #bebebe, -4px -4px 8px #ffffff" }}>
            Next
          </Button>
        </Box>
      </Box>
    );
  } else if (hasRep && activeStep === representativeStep) {
    content = (
      <Box component="form" onSubmit={handleSubmit}>
        <TextField label="Representative Full Name" value={formData.representative.fullName} onChange={(e) => handleChange("representative", "fullName", e.target.value)} fullWidth margin="dense" InputProps={{ sx: { fontSize: "1.4rem", color: "#333" } }} InputLabelProps={{ sx: { fontSize: "1.4rem", color: "#333" } }} />
        <TextField label="Representative Surname" value={formData.representative.surname} onChange={(e) => handleChange("representative", "surname", e.target.value)} fullWidth margin="dense" InputProps={{ sx: { fontSize: "1.4rem", color: "#333" } }} InputLabelProps={{ sx: { fontSize: "1.4rem", color: "#333" } }} />
        <TextField label="Representative ID Number" value={formData.representative.idNumber} onChange={(e) => handleChange("representative", "idNumber", e.target.value)} fullWidth margin="dense" InputProps={{ sx: { fontSize: "1.4rem", color: "#333" } }} InputLabelProps={{ sx: { fontSize: "1.4rem", color: "#333" } }} />
        <TextField label="Representative Email" value={formData.representative.email} onChange={(e) => handleChange("representative", "email", e.target.value)} fullWidth margin="dense" InputProps={{ sx: { fontSize: "1.4rem", color: "#333" } }} InputLabelProps={{ sx: { fontSize: "1.4rem", color: "#333" } }} />
        <TextField label="Representative Cell" value={formData.representative.cell} onChange={(e) => handleChange("representative", "cell", e.target.value)} fullWidth margin="dense" InputProps={{ sx: { fontSize: "1.4rem", color: "#333" } }} InputLabelProps={{ sx: { fontSize: "1.4rem", color: "#333" } }} />
        <Box display="flex" justifyContent="space-between" mt={1}>
          <Button onClick={() => setActiveStep(individualStep)} variant="outlined" sx={{ fontSize: "1.2rem", color: "#333", borderColor: "#ccc" }}>
            Back
          </Button>
          <Button type="submit" variant="contained" sx={{ fontSize: "1.2rem", backgroundColor: "#e0e0e0", color: "#333", boxShadow: "4px 4px 8px #bebebe, -4px -4px 8px #ffffff" }}>
            Next
          </Button>
        </Box>
      </Box>
    );
  }
  if (activeStep >= startAddressStep && activeStep <= endAddressStep) {
    const addressIndex = formData.representative.hasRepresentative ? activeStep - 2 : activeStep - 1;
    content = (
      <Box component="form" onSubmit={handleSubmit}>
        {/* Optional: Only display the number-of-addresses input on the first address step */}
        {addressIndex === 0 && (
          <NeumorphicTextField
            label="Number of Addresses"
            type="number"
            value={addressCount}
            onChange={(e) => {
              const input = e.target.value;
              setAddressCount(input);
              const count = parseInt(input, 10);
              if (!isNaN(count) && count > 0) {
                const addresses = [];
                for (let i = 0; i < count; i++) {
                  addresses.push({
                    complex_name: "",
                    complex_address1: "",
                    complex_address2: "",
                    complex_postalcode: "",
                    complex_province: "",
                  });
                }
                setFormData((prev) => ({ ...prev, addresses }));
              }
            }}
            fullWidth
            margin="dense"
            InputProps={{ sx: { fontSize: "1.4rem", color: "#333" } }}
            InputLabelProps={{ sx: { fontSize: "1.4rem", color: "#333" } }}
          />
        )}
        <Typography sx={{ fontSize: "1.4rem", mt: 1, mb: 1, color: "#333" }}>Address {addressIndex + 1}</Typography>
        {/* Use AddressSearch component here */}
        <AddressSearch
          currentAddressIndex={addressIndex}
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          handleChange={(e, section, field) =>
            // Here, you can wrap your existing handleChange function
            handleAddressChange(addressIndex, field, e.target.value)
          }
        />
        <Box display="flex" justifyContent="space-between" mt={1}>
          <Button onClick={handleBack} variant="outlined" sx={{ fontSize: "1.2rem", color: "#333", borderColor: "#ccc" }}>
            Back
          </Button>
          <Button type="submit" variant="contained" sx={{ fontSize: "1.2rem", backgroundColor: "#e0e0e0", color: "#333", boxShadow: "4px 4px 8px #bebebe, -4px -4px 8px #ffffff" }}>
            Next
          </Button>
        </Box>
      </Box>
    );
  } else if (activeStep === commStep) {
    content = (
      <Box component="form" onSubmit={handleSubmit}>
        <FormControl component="fieldset" margin="dense">
          <FormLabel component="legend" sx={{ fontSize: "1.4rem", color: "#333" }}>
            Communication
          </FormLabel>
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.communication.landlord}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    communication: { ...prev.communication, landlord: e.target.checked },
                  }))
                }
                sx={{ "& .MuiSvgIcon-root": { fontSize: "1.8rem", color: "#333" } }}
              />
            }
            label={<Typography sx={{ fontSize: "1.4rem", color: "#333" }}>Landlord</Typography>}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={formData.communication.representative}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    communication: { ...prev.communication, representative: e.target.checked },
                  }))
                }
                sx={{ "& .MuiSvgIcon-root": { fontSize: "1.8rem", color: "#333" } }}
              />
            }
            label={<Typography sx={{ fontSize: "1.4rem", color: "#333" }}>Representative</Typography>}
          />
          {errors.communication && (
            <Typography variant="body2" color="error" sx={{ fontSize: "1.4rem", display: "block" }}>
              {errors.communication}
            </Typography>
          )}
        </FormControl>
        <Box display="flex" justifyContent="space-between" mt={1}>
          <Button onClick={handleBack} variant="outlined" sx={{ fontSize: "1.2rem", color: "#333", borderColor: "#ccc" }}>
            Back
          </Button>
          <Button type="submit" variant="contained" sx={{ fontSize: "1.2rem", backgroundColor: "#e0e0e0", color: "#333", boxShadow: "4px 4px 8px #bebebe, -4px -4px 8px #ffffff" }}>
            Next
          </Button>
        </Box>
      </Box>
    );
  } else if (activeStep === docsStep) {
    // Documents step
    content = (
      <Box component="form" onSubmit={handleSubmit}>
        <Typography variant="h6" sx={{ fontSize: "1.6rem", mb: 2, color: "#333" }}>
          Upload Documents
        </Typography>

        {/* Municipal Bill */}
        <Box display="flex" alignItems="center">
          <Button variant="outlined" component="label" sx={{ fontSize: "1.4rem", mb: 1, color: "#333", borderColor: "#ccc" }}>
            Municipal Bill
            <input hidden type="file" multiple onChange={(e) => handleFileChange(e, "municipalBill")} />
          </Button>
          {formData.documents.municipalBill && formData.documents.municipalBill.length > 0 && <CheckCircleIcon sx={{ color: "green", fontSize: "2rem", ml: 1 }} />}
        </Box>
        {errors.documents?.municipalBill && (
          <Typography variant="body2" color="error" sx={{ fontSize: "1.4rem", mb: 1 }}>
            {errors.documents.municipalBill}
          </Typography>
        )}

        {/* ID/Passport */}
        <Box display="flex" alignItems="center">
          <Button variant="outlined" component="label" sx={{ fontSize: "1.4rem", mb: 1, color: "#333", borderColor: "#ccc" }}>
            ID/Passport
            <input hidden type="file" multiple onChange={(e) => handleFileChange(e, "idPassport")} />
          </Button>
          {formData.documents.idPassport && formData.documents.idPassport.length > 0 && <CheckCircleIcon sx={{ color: "green", fontSize: "2rem", ml: 1 }} />}
        </Box>
        {errors.documents?.idPassport && (
          <Typography variant="body2" color="error" sx={{ fontSize: "1.4rem", mb: 1 }}>
            {errors.documents.idPassport}
          </Typography>
        )}

        {/* Representative ID/Passport (if applicable) */}
        {formData.representative.hasRepresentative && (
          <>
            <Box display="flex" alignItems="center">
              <Button variant="outlined" component="label" sx={{ fontSize: "1.4rem", mb: 1, color: "#333", borderColor: "#ccc" }}>
                Rep ID/Passport
                <input hidden type="file" multiple onChange={(e) => handleFileChange(e, "representativeIdPassport")} />
              </Button>
              {formData.documents.representativeIdPassport && formData.documents.representativeIdPassport.length > 0 && <CheckCircleIcon sx={{ color: "green", fontSize: "2rem", ml: 1 }} />}
            </Box>
            {errors.documents?.representativeIdPassport && (
              <Typography variant="body2" color="error" sx={{ fontSize: "1.4rem", mb: 1 }}>
                {errors.documents.representativeIdPassport}
              </Typography>
            )}
          </>
        )}

        {/* Bank Letter */}
        <Box display="flex" alignItems="center">
          <Button variant="outlined" component="label" sx={{ fontSize: "1.4rem", mb: 1, color: "#333", borderColor: "#ccc" }}>
            Bank Letter
            <input hidden type="file" multiple onChange={(e) => handleFileChange(e, "bankLetter")} />
          </Button>
          {formData.documents.bankLetter && formData.documents.bankLetter.length > 0 && <CheckCircleIcon sx={{ color: "green", fontSize: "2rem", ml: 1 }} />}
        </Box>
        {errors.documents?.bankLetter && (
          <Typography variant="body2" color="error" sx={{ fontSize: "1.4rem", mb: 1 }}>
            {errors.documents.bankLetter}
          </Typography>
        )}

        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button onClick={handleBack} variant="outlined" sx={{ fontSize: "1.4rem", color: "#333", borderColor: "#ccc" }}>
            Back
          </Button>
          <Button
            type="submit"
            variant="contained"
            sx={{
              fontSize: "1.4rem",
              backgroundColor: "#e0e0e0",
              color: "#333",
              boxShadow: "4px 4px 8px #bebebe, -4px -4px 8px #ffffff",
            }}
          >
            Next
          </Button>
        </Box>
      </Box>
    );
  } else if (activeStep === termsStep) {
    content = (
      <Box component="form" onSubmit={handleSubmit}>
        <FormControlLabel control={<Checkbox checked={formData.termsAccepted} onChange={(e) => setFormData((prev) => ({ ...prev, termsAccepted: e.target.checked }))} sx={{ "& .MuiSvgIcon-root": { fontSize: "1.8rem", color: "#333" } }} />} label={<Typography sx={{ fontSize: "1.4rem", color: "#333" }}>I accept the terms and conditions</Typography>} />
        <Box display="flex" justifyContent="space-between" mt={1}>
          <Button onClick={handleBack} variant="outlined" sx={{ fontSize: "1.2rem", color: "#333", borderColor: "#ccc" }}>
            Back
          </Button>
          <Button type="submit" variant="contained" sx={{ fontSize: "1.2rem", backgroundColor: "#e0e0e0", color: "#333", boxShadow: "4px 4px 8px #bebebe, -4px -4px 8px #ffffff" }}>
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Submit"}
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 8, p: 1, backgroundColor: "#e0e0e0", borderRadius: "16px", boxShadow: "inset 8px 8px 16px #bebebe, inset -8px -8px 16px #ffffff" }}>
      <Typography variant="h5" sx={{ fontSize: "1.8rem", mb: 1, color: "#333" }}>
        Register Individual
      </Typography>
      <Typography variant="subtitle1" sx={{ fontSize: "1.2rem", mb: 1, color: "#333" }}>
        Step {activeStep + 1} of {totalSteps}
      </Typography>
      {content}
      {submissionStatus !== "" && (
        <Dialog
          open={true}
          onClose={() => {}}
          PaperProps={{
            sx: { width: "80%", maxWidth: "500px", p: 3 }, // Increase dialog width and padding
          }}
        >
          <DialogTitle sx={{ fontSize: "1.6rem", fontWeight: "bold" }}>{submissionStatus === "success" ? "Registration Successful" : "Registration Failed"}</DialogTitle>
          <DialogContent sx={{ fontSize: "1.6rem" }}>
            <Typography sx={{ fontSize: "1.2rem" }}>{submissionStatus === "success" ? "Please check your email for further instructions" : "There was an error with your registration. Please try again."}</Typography>
          </DialogContent>
          <DialogActions>
            {submissionStatus === "failure" ? (
              <Button
                onClick={() => {
                  setSubmissionStatus("");
                  setActiveStep(termsStep);
                }}
                color="primary"
              >
                Back
              </Button>
            ) : (
              <Button onClick={() => navigate("/")} color="primary">
                Continue
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
}

export default RegisterIndividualMobile;
