import React, { useState, useEffect } from "react";
import { Chip as MuiChip } from "@mui/material";

const Chip = ({ label, verified }) => {
  const [isZoom125, setIsZoom125] = useState(false);

  useEffect(() => {
    const checkZoom = () => {
      setIsZoom125(window.devicePixelRatio === 1.25);
    };
    checkZoom();
    window.addEventListener("resize", checkZoom);
    return () => window.removeEventListener("resize", checkZoom);
  }, []);

  return (
    <MuiChip
      label={label}
      sx={{
        backgroundColor: verified ? "#2196f3" : "#b0bec5",
        color: "#fff",
        boxShadow: verified
          ? "4px 4px 8px #1976d2, -4px -4px 8px #90caf9"
          : "4px 4px 8px #90a4ae, -4px -4px 8px #eceff1",
        borderRadius: "12px",
        padding: isZoom125 ? "0px" : "2px 2px",
        fontSize: isZoom125 ? "0.6rem" : "0.85rem",
        fontWeight: "bold",
        textTransform: "capitalize",
      }}
    />
  );
};

export default Chip;
