import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";

const EmailStatementPopup = ({ open, onClose, onSubmit }) => {
  const [landlord, setLandlord] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [customEmail, setCustomEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async() => {
    setLoading(true);
    await onSubmit({ landlord, admin, customEmail, subject, body });
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Email Statement Options</DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={<Switch checked={landlord} onChange={(e) => setLandlord(e.target.checked)} />}
          label="Send to Landlord"
        />
        <FormControlLabel
          control={<Switch checked={admin} onChange={(e) => setAdmin(e.target.checked)} />}
          label="Send to Admin"
        />
        <TextField
          margin="dense"
          label="Custom Email"
          fullWidth
          value={customEmail}
          onChange={(e) => setCustomEmail(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Subject"
          fullWidth
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Body"
          fullWidth
          multiline
          rows={4}
          value={body}
          onChange={(e) => setBody(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>Cancel</Button>
        <Button onClick={handleSubmit} disabled={loading}>{loading ? "Loading..." : "Submit"}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmailStatementPopup;
