import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
// import { motion, AnimatePresence } from "framer-motion";


import backgroundImage1 from "../../../assets/bulbs/bulbs0.jpg";
import backgroundImage2 from "../../../assets/bulbs/bulbs5.jpg";
import backgroundImage3 from "../../../assets/bulbs/bulbs6.jpg";
import backgroundImage4 from "../../../assets/bulbs/bulbs8.jpg";
import backgroundImage5 from "../../../assets/electricity/hero4.jpg";


import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import RegisterButton from "./RegisterButton";
import PricingEstimateWrapper from "../pricingEstimate/PricingEstimateWrapper";
import Footer from "./Footer";

const imagesToPreload = [backgroundImage1, backgroundImage2, backgroundImage3, backgroundImage4, backgroundImage5];



function preloadImages(urls) {
  urls.forEach((url) => {
    const img = new Image();
    img.src = url;
  });
}

const sections = [
  { id: 0, slug: "specials", component: <Section1 />, title: "SPECIALS", background: backgroundImage1 },
  { id: 1, slug: "details", component: <Section2 />, background: backgroundImage2 },
  { id: 2, slug: "about", component: <Section3 />, title: "About Us", background: backgroundImage3 },
  { id: 3, slug: "get-in-touch", component: <Section4 />, title: "Get in Touch", background: backgroundImage4 },
  { id: 4, slug: "pricing", component: <PricingEstimateWrapper />, title: "Pricing Estimate", background: backgroundImage5 },
  { id: 5, slug: "footer", component: <Footer />, title: "Contact Information", background: backgroundImage5},//"rgba(255,255,255,1)" },
];

const SectionContainer = styled(Box)(({ theme, background }) => {
  const isColor = background.startsWith("rgba") || background.startsWith("rgb") || background === "white";
  return {
    position: "relative",
    boxSizing: "border-box",
    width: "100%",
    maxWidth: "100%",
    height: "100vh",
    minHeight: "100vh",
    maxHeight: "calc(100vh - 50px)",
    background: isColor
      ? background
      : `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backdropFilter: isColor ? "none" : "blur(10px)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
    color: "#FFF",
    textAlign: "center",
    overflowX: "hidden",
  };
});

const GlassCard = styled(Box)(({ theme }) => ({
  boxSizing: "border-box",
  background: "rgba(255, 255, 255, 0.15)",
  backdropFilter: "blur(12px)",
  WebkitBackdropFilter: "blur(12px)",
  borderRadius: "15px",
  padding: theme.spacing(4),
  boxShadow: "0 8px 30px rgba(0, 0, 0, 0.3)",
  border: "1px solid rgba(255, 255, 255, 0.3)",
  maxWidth: "60%",
  width: "90%",
  textAlign: "center",
  margin: "0 auto",
  overflowX: "hidden",
  [theme.breakpoints.down("sm")]: {
    maxWidth: "85%",
    width: "95%",
    padding: theme.spacing(3),
  },
}));

const WebsitePage = () => {
  const [section, setSection] = useState(0);
  const location = useLocation();
  const maxSections = sections.length - 1;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleScroll = useCallback(
    (event) => {
      setSection((prev) => Math.min(Math.max(prev + (event.deltaY > 0 ? 1 : -1), 0), maxSections));
    },
    [maxSections]
  );

  useEffect(() => {
    preloadImages(imagesToPreload);
  }, []);

  useEffect(() => {
    window.addEventListener("wheel", handleScroll, { passive: true });
    return () => window.removeEventListener("wheel", handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    let targetId = "";
    if (location.hash) {
      targetId = location.hash.slice(1);
    } else if (location.pathname && location.pathname !== "/") {
      targetId = location.pathname.slice(1);
    }
    if (targetId) {
      const element = document.getElementById(targetId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <>
      {!isMobile && <RegisterButton />}
      {sections.map((section) => (
        <SectionContainer key={section.id} background={section.background} id={section.slug}>
          {section.title && (
            <Typography variant="h2" sx={{ mb: 3, fontSize: "clamp(1.5rem, 5vw, 3rem)", position: "relative", zIndex: 2 }}>
              {section.title}
            </Typography>
          )}
          {section.component ? (
            section.component
          ) : (
            <GlassCard>
              <Typography variant="h5" sx={{ fontSize: "clamp(1rem, 4vw, 1.8rem)" }}>
                {section.content}
              </Typography>
            </GlassCard>
          )}
        </SectionContainer>
      ))}
    </>
  );
};

export default WebsitePage;
