import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { initialState } from "../initialState";

export const fetchCustomers = createAsyncThunk("customerManagement/fetchCustomers", async ({ user_uuid }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/customerManagement/customerManagement-onload`, { user_uuid });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || "Failed to fetch details");
  }
});
export const fetchTransactionDetails = createAsyncThunk("customerManagement/fetchTransactionDetails", async ({ filter_date }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/customerManagement/transactionManagement-onload`, { filter_date });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || "Failed to fetch details");
  }
});

export const downloadStatement = createAsyncThunk("customerManagement/downloadStatement", async ({ transactions }, { rejectWithValue }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/customerManagement/download-statment`,
      { transactions },
      { responseType: "blob" }
    );
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || "Failed to download statement");
  }
});
export const emailStatement = createAsyncThunk("customerManagement/emailStatement", 
  async ({ transactions,customer_uuid, landlord, admin, customEmail, subject, body }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/customerManagement/email-statment`,
        { transactions,customer_uuid, landlord, admin, customEmail, subject, body }
      );
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || "Failed to download statement");
  }
});

const customerManagementSlice = createSlice({
  name: "customerManagement",
  initialState: {
    ...initialState,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        state.loading = false;
        state.customers = action.payload.customers;
        console.log(action.payload);
      })
      .addCase(fetchCustomers.rejected, (state, action) => {
        console.log(action);
        state.loading = false;
        state.error = action.payload.message || "An error occurred";
        toast.error(state.error);
      })

      .addCase(fetchTransactionDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTransactionDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.transactions = action.payload.transactions;
        state.payouts = action.payload.payouts;
        console.log(action.payload);
      })
      .addCase(fetchTransactionDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message || "An error occurred";
        toast.error(state.error);
      })

      .addCase(downloadStatement.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(downloadStatement.fulfilled, (state, action) => {
        state.loading = false;
        console.log(action.payload);
      })
      .addCase(downloadStatement.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message || "An error occurred";
        toast.error(state.error);
      })

      .addCase(emailStatement.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(emailStatement.fulfilled, (state, action) => {
        state.loading = false;
        toast.success("Email sent successfully!");
      })
      .addCase(emailStatement.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message || "An error occurred";
        toast.error(state.error);
      });
  },
});

export default customerManagementSlice.reducer;
