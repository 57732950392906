// src/pages/customer/landlord_management/view/TenantTransactions.jsx
import React, { useEffect, useState } from "react";

import { Typography } from "@mui/material";
import { TableBody, TableCell, TableHead, TableRow, Button, Box } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DownloadIcon from "@mui/icons-material/Download";
import EmailIcon from "@mui/icons-material/Email";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import Container from "../../../components/Grid/Container";
import Row from "../../../components/Grid/Row";
import Column from "../../../components/Grid/Column";

import ColumnInset from "../../../components/Grid/ColumnInset";
import ColumnOutset from "../../../components/Grid/ColumnOutset";
import ColumnHeading from "../../../components/Grid/ColumnHeading";

import Table from "../../../components/table/Table";
import TableButton from "../../../components/table/TableButton";
import TableButtonGroup from "../../../components/table/TableButtonGroup";

import TenantCard from "../../../components/cards/TenantCard";

import SearchSelect from "../../../components/filtering/SearchSelect";
import FilterContainer from "../../../components/filtering/FilterContainer";

import HorizontalDivider from "../../../components/misc/HorizontalDivider";

import CardTypography from "../../../components/Typography/CardTypography";

import EditTenant from "../edit/EditTenant";

import { useSelector, useDispatch } from "react-redux";
import { fetchTenantManagement, downloadStatement, emailStatement } from "../../../redux/slices/tenantManagementSlice";

import FilterDate from "../../../components/filtering/FilterDate";
import CircularProgress from "@mui/material/CircularProgress";
import EmailStatementPopup from "../../../components/misc/EmailStatementPopup";

function TenantTransactions({ tenant_uuid, complex, onBack, toggleView }) {
  const dispatch = useDispatch();
  const [filter_date, set__filter_date] = useState(null);
  const customer_uuid = useSelector((state) => state.auth.customer_uuid);

  const [currentView, setCurrentView] = useState("TenantTransactions");
  const [openEmailDialog, setOpenEmailDialog] = useState(false);

  const {
    transactions,
    tenant,
    meter,
    loading,
    error, //
  } = useSelector((state) => state.tenantSlice);
  useEffect(() => {
    if (tenant_uuid) {
      dispatch(fetchTenantManagement({ tenant_uuid, complex, filter_date }));
    }
  }, [tenant_uuid, currentView, filter_date, dispatch]);
  //*******************************************
  const onclick__filter_date = (selectedKey) => {
    const { month, year } = selectedKey;
    set__filter_date(`${month}-01-${year}`); //mm-dd-yyyy for javascript interpretoer
  };

  const onclick__download_statement = async () => {
    try {
      const pdfBlob = await dispatch(downloadStatement({ transactions })).unwrap();
      const blob = new Blob([pdfBlob], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "statement.pdf";
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Transaction update failed:", error);
    }
  };

  const onclick__email_statement = async (data) => {
    try {
      await dispatch(emailStatement({ transactions,customer_uuid, ...data })).unwrap();
      setOpenEmailDialog(false);
    } catch (error) {
      console.error("Email statement failed:", error);
    }
  };

  if (typeof currentView !== "string") {
    return currentView;
  }

  return (
    <>
      <Container>
        <Row>
          <ColumnHeading xs={12} sx={{ display: "flex", flexDirection: "column" }}>
            <Button
              startIcon={<ArrowBackIcon />}
              onClick={onBack}
              variant="contained"
              color="primary"
              sx={{
                position: "fixed",

                bottom: "1px",
                left: "183px",
                width: "400px",
                minWidth: "400px",

                "&:hover": {
                  boxShadow: "inset 1px 1px 3px rgba(0,0,0,0.4), inset -1px -1px 3px rgba(255,255,255,0.5)",
                },
                "&:active": {
                  boxShadow: "inset 3px 3px 6px rgba(0,0,0,0.4), inset -3px -3px 6px rgba(255,255,255,0.5)",
                },
              }}
            >
              Back to Complex Management
            </Button>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ArrowBackIos onClick={() => toggleView("TenantManagement")} sx={{ cursor: "pointer", mr: 1 }} />
              <CardTypography variant="h3">Tenant Transactions</CardTypography>
              <ArrowForwardIos onClick={() => toggleView("TenantManagement")} sx={{ cursor: "pointer", ml: 1 }} />
            </Box>
          </ColumnHeading>
        </Row>
        {/* filters */}
        <Column xs={12} maxHeight={"800px"}>
          <Row spacing={0}>
            <FilterContainer xs={4}>
              <FilterDate onFilter={onclick__filter_date} />
            </FilterContainer>

            <FilterContainer xs={2}></FilterContainer>

            <FilterContainer xs={6} justifyContent="flex-end">
              <Button
                startIcon={<DownloadIcon />}
                onClick={() => onclick__download_statement()}
                disabled={loading}
                sx={{
                  background: "#e0e0e0",
                  color: "#333",
                  boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.5)",
                  "&:hover": {
                    background: "#f0f0f0",
                    boxShadow: "inset 2px 2px 5px rgba(0, 0, 0, 0.1), inset -2px -2px 5px rgba(255, 255, 255, 0.5)",
                  },
                }}
                variant="contained"
              >
                {loading ? "Downloading..." : "Download Statement"}
              </Button>
              <Button
                startIcon={<EmailIcon />}
                onClick={() => setOpenEmailDialog(true)}
                disabled={loading}
                sx={{
                  background: "#e0e0e0",
                  color: "#333",
                  boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.5)",
                  "&:hover": {
                    background: "#f0f0f0",
                    boxShadow: "inset 2px 2px 5px rgba(0, 0, 0, 0.1), inset -2px -2px 5px rgba(255, 255, 255, 0.5)",
                  },
                }}
                variant="contained"
              >
                {loading ? "Emailing..." : "Email Statement"}
              </Button>
            </FilterContainer>
          </Row>

          {/* table */}
          <Row>
            <ColumnInset xs={12} maxHeight="400px">
              <Table maxHeight="400px">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Time</TableCell>
                    <TableCell>Meter </TableCell>
                    <TableCell>Unit </TableCell>
                    <TableCell>Customer</TableCell>
                    <TableCell>transaction </TableCell>
                    <TableCell>Account</TableCell>
                    <TableCell>Total</TableCell>
                    <TableCell>Sale Amnt</TableCell>
                    <TableCell>Sale VAT</TableCell>

                    <TableCell>Arrears</TableCell>
                    <TableCell>Service</TableCell>
                    <TableCell>Step</TableCell>
                    <TableCell>Token</TableCell>

                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactions.map((row, index) => (
                    <TableRow key={row.transaction_number}>
                      <TableCell>{row.transaction_date}</TableCell>
                      <TableCell>{row.transaction_time} </TableCell>
                      <TableCell>{row.meter_number} </TableCell>
                      <TableCell>{row.tenant_unit} </TableCell>
                      <TableCell>{row.customer_name} </TableCell>
                      <TableCell>{row.transaction_number}</TableCell>
                      <TableCell>{row.account_number}</TableCell>
                      <TableCell>{row.total_amount}</TableCell>
                      <TableCell>{row.sale_amount}</TableCell>
                      <TableCell>{row.sale_vat}</TableCell>

                      <TableCell>{row.arrears_amount}</TableCell>
                      <TableCell>{row.service_charge}</TableCell>
                      <TableCell>{row.step_charge}</TableCell>

                      <TableCell>{row.token}</TableCell>

                      <TableCell>
                        <TableButtonGroup>
                          {/* <TableButton onClick={() => alert('todo')} disabled={row.received ? true : false}>RECEIVED</TableButton>
                        <TableButton onClick={() => alert('todo')} disabled={row.paid ? true : false}>
                          PAID
                        </TableButton> */}
                        </TableButtonGroup>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </ColumnInset>
          </Row>
        </Column>
      </Container>

      <EmailStatementPopup open={openEmailDialog} onClose={() => setOpenEmailDialog(false)} onSubmit={onclick__email_statement} />
    </>
  );
}

export default TenantTransactions;
