import React from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import mm_logo from "../../../assets/meterminds/logo3.png";
import { useNavigate } from "react-router-dom";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

function Footer() {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "40vh",
        background: "transparent", //backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        p: theme.spacing(2),
      }}
    >
      {/* Top Half: Contact Info */}
      <Box
        sx={{
          flexBasis: "50%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >

        <Box
          component="ul"
          sx={{
            listStyle: "none",
            p: 0,
            m: 0,
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(2),
            alignItems: "left",
          }}
        >
          <Box component="li" sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <WhatsAppIcon sx={{ color: "#25D366", fontSize: "2rem" }} />
            <Typography
              component="a"
              href="https://wa.me/27723356419?text=Hello%20I%20am%20interested%20in%20your%20sub-metering%20services"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: theme.palette.silverShades.light,
                textDecoration: "none",
                fontSize: "1.5rem",
                "&:hover": { textDecoration: "underline" },
              }}
            >
              Chat with us on WhatsApp
            </Typography>
          </Box>
          <Box component="li" sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <PhoneIcon sx={{ color: theme.palette.silverShades.light, fontSize: "2rem" }} />
            <Typography sx={{ color: theme.palette.silverShades.light, fontSize: "1.5rem" }}>+27 (72) 335 6419</Typography>
          </Box>
          <Box component="li" sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <EmailIcon sx={{ color: theme.palette.silverShades.light, fontSize: "2rem" }} />
            <Typography sx={{ color: theme.palette.silverShades.light, fontSize: "1.5rem" }}>info@meterminds.co.za</Typography>
          </Box>
        </Box>
      </Box>

      {/* Bottom Half: mm_logo */}
      <Box
        sx={{
          flexBasis: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Box
          component="img"
          src={mm_logo}
          alt="Meter Minds Logo"
          sx={{
            maxWidth: "100%",
            maxHeight: "100%",
            objectFit: "contain",
          }}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: theme.spacing(2),
          justifyContent: "center",
          alignItems: "center",
          [theme.breakpoints.down("sm")]: {
            pb: "25px",
          },
        }}
      >
        <Typography component="a" href="https://www.instagram.com/MeterMinds" target="_blank" rel="noopener noreferrer" sx={{ color: theme.palette.silverShades.light }}>
          <InstagramIcon sx={{ fontSize: "2.5rem" }} />
        </Typography>
        <Typography component="a" href="https://www.facebook.com/profile.php?id=61572611942528" target="_blank" rel="noopener noreferrer" sx={{ color: theme.palette.silverShades.light }}>
          <FacebookIcon sx={{ fontSize: "2.5rem" }} />
        </Typography>
      </Box>
    </Box>
  );
}

export default Footer;
