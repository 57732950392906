// src/pages/customer/landlord_management/view/LandlordTransactions.jsx
import React, { useEffect, useState } from "react";

import { TableBody, TableCell, TableHead, TableRow, Button, Box } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import DownloadIcon from "@mui/icons-material/Download";
import EmailIcon from "@mui/icons-material/Email";

import Container from "../../../components/Grid/Container";
import Row from "../../../components/Grid/Row";
import Column from "../../../components/Grid/Column";

import ColumnInset from "../../../components/Grid/ColumnInset";
import ColumnOutset from "../../../components/Grid/ColumnOutset";
import ColumnHeading from "../../../components/Grid/ColumnHeading";

import Table from "../../../components/table/Table";
import TableButton from "../../../components/table/TableButton";
import TableButtonGroup from "../../../components/table/TableButtonGroup";

import ComplexCard from "../../../components/cards/ComplexCard";
import PayoutCard from "../../../components/cards/PayoutCard";

import SearchSelect from "../../../components/filtering/SearchSelect";
import FilterContainer from "../../../components/filtering/FilterContainer";

import HorizontalDivider from "../../../components/misc/HorizontalDivider";
import Chip from "../../../components/misc/Chip";

import CardTypography from "../../../components/Typography/CardTypography";

import { useSelector, useDispatch } from "react-redux";
import { fetchTransactionDetails, downloadStatement, emailStatement } from "../../../redux/slices/landlordManagementSlice";

import LandlordManagement from "./LandlordManagement";
import FilterDate from "../../../components/filtering/FilterDate";
import CircularProgress from "@mui/material/CircularProgress";
import EmailStatementPopup from "../../../components/misc/EmailStatementPopup";

function LandlordTransactions({ landlord }) {
  const dispatch = useDispatch();
  const [openEmailDialog, setOpenEmailDialog] = useState(false);
  const customer_uuid = useSelector((state) => state.auth.customer_uuid);
  // const customer_uuid = useSelector((state) => state.auth.customer_uuid);
  //API `/landlordManagement-onload`
  //*******************************************
  const [filter_date, set__filter_date] = useState(null);
  const [currentView, setCurrentView] = useState("LandlordTransactions");
  const { transactions, complex, loading } = useSelector((state) => state.landlordSlice);
  useEffect(() => {
    if (landlord) {
      dispatch(fetchTransactionDetails({ customer_uuid: landlord.customer_uuid, filter_date }));
    }
  }, [landlord, currentView, filter_date, dispatch]);
  //*******************************************
  const onclick__filter_date = (selectedKey) => {
    const { month, year } = selectedKey;
    set__filter_date(`${month}-01-${year}`); //mm-dd-yyyy for javascript interpretoer
  };


  const onclick__download_statement = async () => {
    try {
      const pdfBlob = await dispatch(downloadStatement({ transactions })).unwrap();
      const blob = new Blob([pdfBlob], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "statement.pdf";
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Transaction update failed:", error);
    }
  };

    const onclick__email_statement = async (data) => {
      try {
        await dispatch(emailStatement({ transactions,customer_uuid, ...data })).unwrap();
        setOpenEmailDialog(false);
      } catch (error) {
        console.error("Email statement failed:", error);
      }
    };

  //*******************************************
  if (typeof currentView !== "string") {
    return currentView;
  }

  if (currentView === "landlordManagement") {
    return <LandlordManagement />;
  }

  return (
    <>
    <Container>
      <Row>
        <ColumnHeading xs={12} sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ArrowBackIos onClick={() => setCurrentView("landlordManagement")} sx={{ cursor: "pointer", mr: 1 }} />
            <CardTypography variant={"h3"}>Landlord Transaction Management</CardTypography>
            <ArrowForwardIos onClick={() => setCurrentView("landlordManagement")} sx={{ cursor: "pointer", ml: 1 }} />
          </Box>
        </ColumnHeading>
      </Row>

      {/* filters */}
      <Column xs={9} maxHeight={"800px"}>
        <Row spacing={0}>
          {/* <FilterContainer xs={4}>
            <SearchSelect
              type="dropdown"
              label="Filter by Tenant"
              data={[
                { key: "Tenant1", value: "8528521" },
                { key: "Tenant2", value: "18252" },
                { key: "Tenant3", value: "963287413" },
              ]}
              onFilter={handleDropdownFilter}
            />
            <SearchSelect
              type="search"
              label="Search"
              data={null} // Not required for search
              onFilter={handleSearchFilter}
            />
          </FilterContainer>

          <FilterContainer xs={4}>
            <SearchSelect
              type="dropdown"
              label="Filter by Meter"
              data={[
                { key: "Meter1", value: "8528521" },
                { key: "cMeter2", value: "18252" },
                { key: "Meter3", value: "963287413" },
              ]}
              onFilter={handleDropdownFilter}
            />
            <SearchSelect
              type="search"
              label="Search"
              data={null} // Not required for search
              onFilter={handleSearchFilter}
            />
          </FilterContainer> */}

          <FilterContainer xs={4}>
            <FilterDate onFilter={onclick__filter_date} />
          </FilterContainer>

          <FilterContainer xs={2}></FilterContainer>

          <FilterContainer xs={6} justifyContent="flex-end">
            <Button
              startIcon={<DownloadIcon />}
              onClick={() => onclick__download_statement()}
              disabled={loading}
              sx={{
                background: "#e0e0e0",
                color: "#333",
                boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.5)",
                "&:hover": {
                  background: "#f0f0f0",
                  boxShadow: "inset 2px 2px 5px rgba(0, 0, 0, 0.1), inset -2px -2px 5px rgba(255, 255, 255, 0.5)",
                },
              }}
              variant="contained"
            >
              {loading ? "Downloading..." : "Download Statement"}
            </Button>
            <Button
                startIcon={<EmailIcon />}
                onClick={() => setOpenEmailDialog(true)}
                disabled={loading}
                sx={{
                  background: "#e0e0e0",
                  color: "#333",
                  boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.5)",
                  "&:hover": {
                    background: "#f0f0f0",
                    boxShadow: "inset 2px 2px 5px rgba(0, 0, 0, 0.1), inset -2px -2px 5px rgba(255, 255, 255, 0.5)",
                  },
                }}
                variant="contained"
              >
                {loading ? "Emailing..." : "Email Statement"}
              </Button>
          </FilterContainer>
        </Row>

        <Row>
          <ColumnInset xs={12} maxHeight="400px">
            <Table maxHeight="400px">
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Time</TableCell>
                  <TableCell>Meter Nr</TableCell>
                  <TableCell>Customer</TableCell>
                  <TableCell>Transaction Nr</TableCell>
                  <TableCell>Acc Nr</TableCell>
                  <TableCell>Sale Amnt</TableCell>
                  <TableCell>Sale VAT</TableCell>

                  <TableCell>Arrears</TableCell>
                  <TableCell>Service</TableCell>
                  <TableCell>Step</TableCell>
                  <TableCell>Token</TableCell>

                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions.map((row, index) => (
                  <TableRow key={row.transaction_number}>
                    <TableCell>{row.transaction_date}</TableCell>
                    <TableCell>{row.transaction_time} </TableCell>
                    <TableCell>{row.meter_number} </TableCell>
                    <TableCell>{row.customer_name} </TableCell>
                    <TableCell>{row.transaction_number}</TableCell>
                    <TableCell>{row.account_number}</TableCell>
                    <TableCell>{row.sale_amount}</TableCell>
                    <TableCell>{row.sale_vat}</TableCell>

                    <TableCell>{row.arrears_amount}</TableCell>
                    <TableCell>{row.service_charge}</TableCell>
                    <TableCell>{row.step_charge}</TableCell>

                    <TableCell>{row.token}</TableCell>

                    <TableCell>
                      <TableButtonGroup>
                        {/* <TableButton onClick={() => console.log({ tenant: row })}>View</TableButton>
                        <TableButton onClick={() => console.log({ tenant: row })} disabled={row.debriefed ? true : false}>
                          Debrief
                        </TableButton> */}
                      </TableButtonGroup>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ColumnInset>
        </Row>
      </Column>

      <Column xs={3}>
        <Row>
          <ColumnOutset xs={12} padding={0}>
            <PayoutCard complex={complex} paymentInfo={{}} />
          </ColumnOutset>
        </Row>
      </Column>

      <HorizontalDivider width="100%" margin={58} />
    </Container>
    
    <EmailStatementPopup open={openEmailDialog} onClose={() => setOpenEmailDialog(false)} onSubmit={onclick__email_statement} />
   
    </>
  );
}

export default LandlordTransactions;
