import React from "react";
import { Box, Typography, Button, Divider } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import DownloadIcon from "@mui/icons-material/Download";
import CardTypography from "../Typography/CardTypography";
import Chip from "../misc/Chip";
import { useSelector } from "react-redux";

const ComplexCard = ({ complex, card__meter_details, callback_editcomplex }) => {
  const neumorphicStyles = {
    boxShadow: "7px 7px 15px rgba(0, 0, 0, 0.2), -7px -7px 15px rgba(255, 255, 255, 0.5)",
    borderRadius: "15px",
    background: "#e0e0e0",
    padding: "20px",
    margin: "20px 0",
  };

  const iconStyles = {
    cursor: "pointer",
    boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.5)",
    borderRadius: "50%",
    background: "#e0e0e0",
    padding: "5px",
    transition: "transform 0.3s, box-shadow 0.3s",
    "&:hover": {
      transform: "scale(1.1)",
      boxShadow: "6px 6px 12px rgba(0, 0, 0, 0.3), -6px -6px 12px rgba(255, 255, 255, 0.7)",
    },
  };

  const auth = useSelector((state) => state.auth);

  const click_editcomplex = ({ complex_uuid }) => {
    callback_editcomplex({ complex_uuid });
  };

  return (
    <Box sx={{ maxWidth: 600, margin: "auto", ...neumorphicStyles }}>
      {/* Landlord Details */}
      <CardTypography variant="h4">Complex Details</CardTypography>
      <Divider sx={{ marginY: 1 }} />
      <Box>
        <CardTypography>Complex: {complex.complex_uuid}</CardTypography>
        <CardTypography>Name: {complex.complex_name}</CardTypography>
        <CardTypography>Address 1: {complex.complex_address1}</CardTypography>
        <CardTypography>Address 2: {complex.complex_address2}</CardTypography>
        <CardTypography>Postal Code: {complex.complex_postalcode}</CardTypography>
        <CardTypography>Province: {complex.complex_province}</CardTypography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Chip label={complex.complex_verified ? "Verified" : "Unverified"} verified={complex.complex_verified} />
        </Box>
      </Box>

      {auth.role == "admin" && (
      <Button
        onClick={() => click_editcomplex({ complex_uuid: complex.complex_uuid })}
        sx={{
          marginTop: 2,
          background: "#e0e0e0",
          color: "#333",
          boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.5)",
          "&:hover": {
            background: "#f0f0f0",
            boxShadow: "inset 2px 2px 5px rgba(0, 0, 0, 0.1), inset -2px -2px 5px rgba(255, 255, 255, 0.5)",
          },
        }}
        variant="contained"
      >
        Edit
      </Button>)}

      {/* Bank Details */}
      <CardTypography variant="h4" sx={{ marginTop: 4 }}>
        Meter Details
      </CardTypography>
      <Divider sx={{ marginY: 1 }} />
      <Box>
        <Box key={1} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginY: 1 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CheckCircleIcon color="success" sx={{ marginRight: 1 }} />
            <CardTypography>Tenants with meters: {card__meter_details.connected_meters}</CardTypography>
          </Box>
          <DownloadIcon sx={iconStyles} />
        </Box>
        <Box key={2} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginY: 1 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CancelIcon color="error" sx={{ marginRight: 1 }} />
            <CardTypography>Tenants without meters: {card__meter_details.disconnected_meters}</CardTypography>
          </Box>
          <DownloadIcon sx={iconStyles} />
        </Box>
      </Box>

      {/* <Button
        sx={{
          marginTop: 2,
          background: "#e0e0e0",
          color: "#333",
          boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.5)",
          "&:hover": {
            background: "#f0f0f0",
            boxShadow: "inset 2px 2px 5px rgba(0, 0, 0, 0.1), inset -2px -2px 5px rgba(255, 255, 255, 0.5)",
          },
        }}
        variant="contained"
      >
        Edit
      </Button> */}
    </Box>
  );
};

export default ComplexCard;
