// src/pages/customer/landlord_management/view/TenantManagement.jsx
import React, { useEffect, useState } from "react";

import { Typography } from "@mui/material";
import { TableBody, TableCell, TableHead, TableRow, Button, Box } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DownloadIcon from "@mui/icons-material/Download";
import EmailIcon from "@mui/icons-material/Email";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import Container from "../../../components/Grid/Container";
import Row from "../../../components/Grid/Row";
import Column from "../../../components/Grid/Column";

import ColumnInset from "../../../components/Grid/ColumnInset";
import ColumnOutset from "../../../components/Grid/ColumnOutset";
import ColumnHeading from "../../../components/Grid/ColumnHeading";

import Table from "../../../components/table/Table";
import TableButton from "../../../components/table/TableButton";
import TableButtonGroup from "../../../components/table/TableButtonGroup";

import TenantCard from "../../../components/cards/TenantCard";

import SearchSelect from "../../../components/filtering/SearchSelect";
import FilterContainer from "../../../components/filtering/FilterContainer";

import HorizontalDivider from "../../../components/misc/HorizontalDivider";

import CardTypography from "../../../components/Typography/CardTypography";

import EditTenant from "../edit/EditTenant";

import { useSelector, useDispatch } from "react-redux";
import { fetchTenantManagement } from "../../../redux/slices/tenantManagementSlice";

import FilterDate from "../../../components/filtering/FilterDate";

function TenantManagement({ tenant_uuid, complex, onBack, toggleView }) {
  const dispatch = useDispatch();
  const [filter_date, set__filter_date] = useState(null);
  // const customer_uuid = useSelector((state) => state.auth.customer_uuid);
  const [currentView, setCurrentView] = useState("TenantManagement");
  //API `/landlordManagement-onload`
  //*******************************************
  const {
    transactions,
    tenant,
    meter,
    loading,
    error, //
  } = useSelector((state) => state.tenantSlice);
  useEffect(() => {
    if (tenant_uuid) {
      dispatch(fetchTenantManagement({ tenant_uuid, complex, filter_date }));
    }
  }, [tenant_uuid, currentView, filter_date, dispatch]);
  //*******************************************
  const onclick__filter_date = (selectedKey) => {
    const { month, year } = selectedKey;
    set__filter_date(`${month}-01-${year}`); //mm-dd-yyyy for javascript interpretoer
  };

  const handleDropdownFilter = (selectedKey) => {
    console.log("Dropdown filter triggered:", selectedKey);
    // Perform filtering or API call based on selectedKey
  };
  const handleSearchFilter = (inputValue) => {
    console.log("Search filter triggered:", inputValue);
    // Perform search or API call based on inputValue
  };

  //*******************************************
  //add tenant clicked
  const click__capture_Tenant = () => {
    setCurrentView("CaptureTenant");
  };
  //*******************************************

  //upload tenants csv:
  const [UploadFile_tenants, set__UploadFile_tenants] = useState(true);
  const click__UploadFile_tenants = (file) => {
    console.log("Uploaded file:", file);
    // Add your file processing logic here
  };
  const click__downloadFile_tenants = (file) => {
    set__UploadFile_tenants(false);
    console.log("Uploaded file:", file);
    // Add your file processing logic here
  };

  //*******************************************
  //view clicked
  const [selectedTenant, setSelectedTenant] = useState(null);
  const click__view_Tenant = ({ tenant }) => {
    setSelectedTenant(tenant);
    setCurrentView("viewTenant");
  };
  //*******************************************

  //edit clicked
  const click__edit_tenant = ({ tenant_uuid }) => {
    setCurrentView(() => <EditTenant tenant_uuid={tenant_uuid} onBack={() => setCurrentView("TenantManagement")} heading={"Back to Tenant Management"} />);
  };

  if (typeof currentView !== "string") {
    return currentView;
  }

  return (
    <Container>
      <Row>
        <ColumnHeading xs={12} sx={{ display: "flex", flexDirection: "column" }}>
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={onBack}
            variant="contained"
            color="primary"
            sx={{
              position: "fixed",

              bottom: "1px",
              left: "183px",
              width: "400px",
              minWidth: "400px",

              "&:hover": {
                boxShadow: "inset 1px 1px 3px rgba(0,0,0,0.4), inset -1px -1px 3px rgba(255,255,255,0.5)",
              },
              "&:active": {
                boxShadow: "inset 3px 3px 6px rgba(0,0,0,0.4), inset -3px -3px 6px rgba(255,255,255,0.5)",
              },
            }}
          >
            Back to Complex Management
          </Button>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ArrowBackIos onClick={() => toggleView("TenantTransactions")} sx={{ cursor: "pointer", mr: 1 }} />
            <CardTypography variant="h3">Tenant Management</CardTypography>
            <ArrowForwardIos onClick={() => toggleView("TenantTransactions")} sx={{ cursor: "pointer", ml: 1 }} />
          </Box>
        </ColumnHeading>
      </Row>

      <Row>
        <ColumnHeading xs={12}>
          <CardTypography variant={"h4"}>Details</CardTypography>
        </ColumnHeading>
        <ColumnInset xs={4} padding={0}></ColumnInset>
        <ColumnOutset xs={4} padding={0}>
          <TenantCard tenant={tenant} meter={meter} callback_edittenant={click__edit_tenant} />
        </ColumnOutset>
        <ColumnInset xs={4} padding={0}></ColumnInset>
      </Row>

    </Container>
  );
}

export default TenantManagement;
