// src/pages/admin/customer_management/CustomerManagement.jsx
import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
import { fetchCustomers } from "../../../redux/slices/customerManagementSlice";

import { TableBody, TableCell, TableHead, TableRow, Box } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

import Container from "../../../components/Grid/Container";
import Row from "../../../components/Grid/Row";
import Column from "../../../components/Grid/Column";

import ColumnInset from "../../../components/Grid/ColumnInset";
import ColumnOutset from "../../../components/Grid/ColumnOutset";
import ColumnHeading from "../../../components/Grid/ColumnHeading";

import SearchSelect from "../../../components/filtering/SearchSelect";
import FilterContainer from "../../../components/filtering/FilterContainer";

import Table from "../../../components/table/Table";
import VerticalTable from "../../../components/table/VerticalTable";
import TableButton from "../../../components/table/TableButton";
import TableButtonGroup from "../../../components/table/TableButtonGroup";

import CardTypography from "../../../components/Typography/CardTypography";
import Chip from "../../../components/misc/Chip";

import LandlordManagement from "../landlordManagement/LandlordManagement";
import TransactionManagement from "../landlordManagement/TransactionManagement";

import EditLandlord from "../edit/EditLandlord";

import CustomerTransactions from "./CustomerTransactions";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

function CustomerManagement() {
  const dispatch = useDispatch();
  const user_uuid = useSelector((state) => state.auth.user_uuid);
  const [currentView, setCurrentView] = useState("CustomerManagement");

  const {
    customers,
    loading,
    error, //
  } = useSelector((state) => state.customerSlice);
  useEffect(() => {
    if (user_uuid) {
      dispatch(fetchCustomers({ user_uuid }));
    } else {
      alert("no user_uuid");
    }
  }, [user_uuid, currentView, dispatch]);

  //filter
  const handleDropdownFilter = (selectedKey) => {
    console.log("Dropdown filter triggered:", selectedKey);
    // Perform filtering or API call based on selectedKey
  };
  const handleSearchFilter = (inputValue) => {
    console.log("Search filter triggered:", inputValue);
    // Perform search or API call based on inputValue
  };

  //view clicked
  const [selectedLandlord, setSelectedLandlord] = useState(null);
  const click__view_customer = ({ landlord }) => {
    setSelectedLandlord(landlord);
    setCurrentView("LandlordManagement");
  };
  //*******************************************

  //edit clicked
  const click__edit_customer = ({ customer_uuid }) => {
    setCurrentView(() => <EditLandlord customer_uuid={customer_uuid} onBack={() => setCurrentView("CustomerManagement")} heading="back to customer management" />);
  };
  //*******************************************
  const toggleLandlordView = (target) => {
    if (target === "TransactionManagement") {
      setCurrentView("TransactionManagement");
    } else if (target === "LandlordManagement") {
      setCurrentView("LandlordManagement");
    }
  };

  if (typeof currentView !== "string") {
    return currentView;
  }

  if (currentView === "LandlordManagement") {
    return <LandlordManagement landlord={selectedLandlord} onBack={() => setCurrentView("CustomerManagement")} toggleView={toggleLandlordView} />;
  } else if (currentView === "TransactionManagement") {
    return <TransactionManagement landlord={selectedLandlord} onBack={() => setCurrentView("CustomerManagement")} toggleView={toggleLandlordView} />;
  } else if (currentView === "CustomerTransactions") {
    return <CustomerTransactions />;
  }

  return (
    <Container>
      <Row>
        <ColumnHeading xs={12} sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ArrowBackIos onClick={() => setCurrentView("CustomerTransactions")} sx={{ cursor: "pointer", mr: 1 }} />
            <CardTypography variant={"h3"}>Customer Management</CardTypography>
            <ArrowForwardIos onClick={() => setCurrentView("CustomerTransactions")} sx={{ cursor: "pointer", ml: 1 }} />
          </Box>
        </ColumnHeading>
      </Row>

      {/* filters */}
      <Column xs={8} maxHeight={"800px"}>
        <Row spacing={0}>
          <FilterContainer xs={6}>
            <SearchSelect
              type="dropdown"
              label="Filter by Customer"
              data={[
                { key: "Tenant1", value: "8528521" },
                { key: "Tenant2", value: "18252" },
                { key: "Tenant3", value: "963287413" },
              ]}
              onFilter={handleDropdownFilter}
            />
            <SearchSelect
              type="search"
              label="Search"
              data={null} // Not required for search
              onFilter={handleSearchFilter}
            />
          </FilterContainer>

          <FilterContainer xs={6}>
            <SearchSelect
              type="dropdown"
              label="Filter by Meter"
              data={[
                { key: "Meter1", value: "8528521" },
                { key: "cMeter2", value: "18252" },
                { key: "Meter3", value: "963287413" },
              ]}
              onFilter={handleDropdownFilter}
            />
            <SearchSelect
              type="search"
              label="Search"
              data={null} // Not required for search
              onFilter={handleSearchFilter}
            />
          </FilterContainer>
        </Row>
      </Column>

      {/* table */}
      <Column xs={12} maxHeight={"750px"}>
        <Row>
          <ColumnInset xs={12} maxHeight="650px">
            <Table maxHeight="200px">
              <TableHead>
                <TableRow>
                  <TableCell>customer_uuid</TableCell>
                  <TableCell>user_type</TableCell>
                  <TableCell>customer_email</TableCell>
                  <TableCell>customer_cell</TableCell>
                  <TableCell>customer_id</TableCell>
                  <TableCell>Vending Fee</TableCell>

                  <TableCell>Actions</TableCell>

                  <TableCell>Customer details</TableCell>
                  <TableCell>banking details</TableCell>
                  <TableCell>Representative Details</TableCell>
                  <TableCell>Communication Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customers.map((row) => (
                  <TableRow key={row.customer_uuid}>
                    <TableCell>{row.customer_uuid}</TableCell>
                    <TableCell>{row.user_type}</TableCell>
                    <TableCell>{row.customer_email}</TableCell>
                    <TableCell>{row.customer_cell}</TableCell>
                    <TableCell>{row.customer_id}</TableCell>
                    <TableCell>{row.customer_vending_fee} %</TableCell>

                    <TableCell>
                      <TableButtonGroup>
                        <TableButton onClick={() => click__view_customer({ landlord: row })}>View</TableButton>
                        <TableButton onClick={() => click__edit_customer({ customer_uuid: row.customer_uuid })}>Edit</TableButton>
                      </TableButtonGroup>
                    </TableCell>

                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Chip label={row.customer_verified ? "Verified" : "Unverified"} verified={row.customer_verified} />
                      </Box>
                    </TableCell>

                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {row.customer_account_number ? <CheckCircleIcon color="success" sx={{ marginRight: 1 }} /> : <CancelIcon color="error" sx={{ marginRight: 1 }} />}
                        <Chip label={row.bank_verified ? "Verified" : "Unverified"} verified={row.bank_verified} />
                      </Box>
                    </TableCell>

                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {row.customer_representative ? <CheckCircleIcon color="success" sx={{ marginRight: 1 }} /> : <CancelIcon color="error" sx={{ marginRight: 1 }} />}
                        <Chip label={row.representative_verified ? "Verified" : "Unverified"} verified={row.representative_verified} />
                      </Box>
                    </TableCell>

                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {row.communication_landlord || row.communication_representative ? <CheckCircleIcon color="success" sx={{ marginRight: 1 }} /> : <CancelIcon color="error" sx={{ marginRight: 1 }} />}
                        <Chip label={row.communication_verified ? "Verified" : "Unverified"} verified={row.communication_verified} />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ColumnInset>
        </Row>
      </Column>
    </Container>
  );
}

export default CustomerManagement;
