//redux/slices/landlord_login/customerSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { initialState } from "../initialState";

export const fetchLandlordDetails = createAsyncThunk("landlordManagement/fetchLandlordDetails", async (customer_uuid, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/landlordManagement/landlordManagement-onload`, { customer_uuid });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || "Failed to fetch details");
  }
});

export const fetchTransactionDetails = createAsyncThunk("landlordManagement/fetchTransactionDetails", async ({ customer_uuid, filter_date }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/landlordManagement/transactionManagement-onload`, { customer_uuid, filter_date });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || "Failed to fetch details");
  }
});

export const downloadStatement = createAsyncThunk("landlordManagement/downloadStatement", async ({ transactions }, { rejectWithValue }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/landlordManagement/download-statment`,
      { transactions },
      { responseType: "blob" }
    );
     return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || "Failed to download statement");
  }
});

export const emailStatement = createAsyncThunk("landlordManagement/emailStatement", 
  async ({ transactions,customer_uuid, landlord, admin, customEmail, subject, body }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/landlordManagement/email-statment`,
        { transactions,customer_uuid, landlord, admin, customEmail, subject, body }
      );
     return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || "Failed to download statement");
  }
});

const landlordManagementSlice = createSlice({
  name: "landlordManagement",
  initialState: {
    ...initialState,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLandlordDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLandlordDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.complexes = action.payload.complexes;
        state.customer = action.payload.customer;
        state.processed__customer_documents = action.payload.processed__customer_documents;
        state.customer_representative = action.payload.customer_representative;
        state.customer_communication = action.payload.customer_communication;
        state.customer_documents = action.payload.customer_documents;
        console.log(action.payload);
      })
      .addCase(fetchLandlordDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message || "An error occurred";
        toast.error(state.error);
      })

      .addCase(fetchTransactionDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTransactionDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.transactions = action.payload.transactions;
        state.payouts = action.payload.payouts;
      })
      .addCase(fetchTransactionDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message || "An error occurred";
        toast.error(state.error);
      })

      .addCase(downloadStatement.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(downloadStatement.fulfilled, (state, action) => {
        state.loading = false;
        console.log(action.payload);
      })
      .addCase(downloadStatement.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message || "An error occurred";
        toast.error(state.error);
      })

      .addCase(emailStatement.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(emailStatement.fulfilled, (state, action) => {
        state.loading = false;
        toast.success("Email sent successfully!");
      })
      .addCase(emailStatement.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message || "An error occurred";
        toast.error(state.error);
      });
  },
});

export default landlordManagementSlice.reducer;
