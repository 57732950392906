import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import backgroundImage1 from "../../../assets/specials/sale1.jpg";
import backgroundImage2 from "../../../assets/specials/sale4.png";
import backgroundImage3 from "../../../assets/specials/sale3.jpg";

const CarouselContainer = styled(Box)(({ theme }) => ({
  width: "90vw",
  maxWidth: "1200px",
  height: "70vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  overflow: "hidden",
  borderRadius: "15px",
  boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.2)",
  margin: "0 auto",
  [theme.breakpoints.down("sm")]: {
    width: "92vw",
  },
}));

const Slide = styled(Box)(({ image }) => ({
    width: "100%",
    height: "100%",
    background: `url(${image}) center/contain no-repeat`,
    borderRadius: "15px",
}));

const InfoCard = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: theme.spacing(4),
  left: theme.spacing(14),
  right: theme.spacing(14),
  padding: theme.spacing(3),
  borderRadius: "15px",
  background: "rgba(0, 0, 0, 0.6)",
  boxShadow: "8px 8px 16px rgba(0, 0, 0, 0.5), -8px -8px 16px rgba(255, 255, 255, 0.2)",
  backdropFilter: "blur(10px)",
  color: "#fff",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
    left: theme.spacing(1),
    right: theme.spacing(1),
    bottom: theme.spacing(1),
  },
}));

const slides = [
  {
    image: backgroundImage1,
    title: "SINGLE-PHASE INTEGRATED PREPAID METER",
    description: "This STS compliant integrated meter contains all the critical metering, token descryption, relay disconnection and reverse energy detection functionality.",
  },
  {
    image: backgroundImage2,
    title: "THREE-PHASE",
    description: "Advanced features for comprehensive energy management across multiple phase and data analytics.",
  },
  // {
  //   image: backgroundImage3,
  //   title: "DIGITAL ENERGY MONITOR",
  //   description: "Efficiently track and manage energy consumption with our state-of-the-art digital monitoring solutions.",
  // },
];

const Section1 = () => {
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => setCurrent((prev) => (prev + 1) % slides.length), 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <CarouselContainer>
      <Slide image={slides[current].image} />
      <InfoCard>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            mb: 1,
            fontSize: { xs: "1.25rem", sm: "1.5rem", md: "1.75rem" },
            textShadow: "1px 1px 3px rgba(0,0,0,0.8)",
          }}
        >
          {slides[current].title}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: "0.9rem", sm: "1rem", md: "1.125rem" },
            textShadow: "1px 1px 3px rgba(0,0,0,0.8)",
          }}
        >
          {slides[current].description}
        </Typography>
      </InfoCard>
    </CarouselContainer>
  );
};

export default Section1;
