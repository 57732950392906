// src/pages/customer/tenantManagement/TenantManagement.jsx
import React, { useEffect, useState } from "react";

import { Typography } from "@mui/material";
import { TableBody, TableCell, TableHead, TableRow, Button, Box } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DownloadIcon from "@mui/icons-material/Download";
import EmailIcon from "@mui/icons-material/Email";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import Container from "../../../components/Grid/Container";
import Row from "../../../components/Grid/Row";
import Column from "../../../components/Grid/Column";

import ColumnInset from "../../../components/Grid/ColumnInset";
import ColumnOutset from "../../../components/Grid/ColumnOutset";
import ColumnHeading from "../../../components/Grid/ColumnHeading";

import Table from "../../../components/table/Table";
import TableButton from "../../../components/table/TableButton";
import TableButtonGroup from "../../../components/table/TableButtonGroup";

import TenantCard from "../../../components/cards/TenantCard";
import CustomerManagementFee from "../../../components/cards/CustomerManagementFee";
import CustomerFixedFee from "../../../components/cards/CustomerFixedFee";
import CustomerArrearsFee from "../../../components/cards/CustomerArrearsFee";

import SearchSelect from "../../../components/filtering/SearchSelect";
import FilterContainer from "../../../components/filtering/FilterContainer";

import HorizontalDivider from "../../../components/misc/HorizontalDivider";

import CardTypography from "../../../components/Typography/CardTypography";

import EditTenant from "../edit/EditTenant";
import EditMeter from "../edit/EditMeter";
import EditManagementFee from "../edit/EditManagementFee";
import EditFixedFee from "../edit/EditFixedFee";
import EditArrearsFee from "../edit/EditArrearsFee";

import { useSelector, useDispatch } from "react-redux";
import { fetchTenantManagement } from "../../../redux/slices/tenantManagementSlice";
import { update__transaction_received, update__transaction_paid } from "../../../redux/slices/admin";

import FilterDate from "../../../components/filtering/FilterDate";

function TenantManagement({ tenant_uuid, complex, onBack, toggleView }) {
  console.log(complex)
  const dispatch = useDispatch();
  // const customer_uuid = useSelector((state) => state.auth.customer_uuid);

  //API `/landlordManagement-onload`
  //*******************************************
  const [filter_date, set__filter_date] = useState(null);
  const [currentView, setCurrentView] = useState("TenantManagement");
  const {
    transactions,
    payouts,
    tenant,
    meter,

    management_fee,
    fixed_fee,
    arrears_fee,

    loading,
    error, //
  } = useSelector((state) => state.tenantSlice);
  useEffect(() => {
    if (tenant_uuid) {
      dispatch(fetchTenantManagement({ tenant_uuid, complex, filter_date }));
    }
  }, [tenant_uuid, currentView, filter_date, dispatch]);
  //*******************************************

  //*******************************************

  //edit clicked
  const click__edit_tenant = ({ tenant_uuid }) => {
    setCurrentView(() => <EditTenant tenant_uuid={tenant_uuid} onBack={() => setCurrentView("TenantManagement")} heading={"Back to Tenant Management"} />);
  };
  //*******************************************

  const click__edit_meter = ({ meter_uuid, tenant_uuid }) => {
    setCurrentView(() => <EditMeter meter_uuid={meter_uuid} tenant_uuid={tenant_uuid} onBack={() => setCurrentView("TenantManagement")} heading={"Back to Tenant Management"} />);
  };
  //*******************************************

  const click__edit_managementFee = ({ management_uuid, tenant_uuid }) => {
    setCurrentView(() => <EditManagementFee tenant_uuid={tenant_uuid} management_fee={management_fee} onBack={() => setCurrentView("TenantManagement")} heading={"Back to Tenant Management"} />);
  };
  const click__edit_fixedFee = ({ fixed_uuid, tenant_uuid }) => {
    setCurrentView(() => <EditFixedFee tenant_uuid={tenant_uuid} fixed_fee={fixed_fee} onBack={() => setCurrentView("TenantManagement")} heading={"Back to Tenant Management"} />);
  };
  const click__edit_arrearsFee = ({ arrears_uuid, tenant_uuid }) => {
    setCurrentView(() => <EditArrearsFee tenant_uuid={tenant_uuid} arrears_fee={arrears_fee} onBack={() => setCurrentView("TenantManagement")} heading={"Back to Tenant Management"} />);
  };

  const click__edit_meterActive = async ({ meter_uuid, isMeterActive }) => {
    alert(`${meter_uuid} : ${isMeterActive}`);
  };

  if (typeof currentView !== "string") {
    return currentView;
  }

  return (
    <Container>
      <Row>
        <ColumnHeading xs={12} sx={{ display: "flex", flexDirection: "column" }}>
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={onBack}
            variant="contained"
            color="primary"
            sx={{
              position: "fixed",

              bottom: "1px",
              left: "183px",
              width: "400px",
              minWidth: "400px",

              "&:hover": {
                boxShadow: "inset 1px 1px 3px rgba(0,0,0,0.4), inset -1px -1px 3px rgba(255,255,255,0.5)",
              },
              "&:active": {
                boxShadow: "inset 3px 3px 6px rgba(0,0,0,0.4), inset -3px -3px 6px rgba(255,255,255,0.5)",
              },
            }}
          >
            Back to Complex Management
          </Button>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ArrowBackIos onClick={() => toggleView("TenantTransactions")} sx={{ cursor: "pointer", mr: 1 }} />
            <CardTypography variant="h3">Tenant Management</CardTypography>
            <ArrowForwardIos onClick={() => toggleView("TenantTransactions")} sx={{ cursor: "pointer", ml: 1 }} />
          </Box>
        </ColumnHeading>
      </Row>

      <Row>
        <ColumnHeading xs={12}>
          <CardTypography variant={"h4"}>Details</CardTypography>
        </ColumnHeading>
        <ColumnInset xs={4} padding={0}></ColumnInset>
        <ColumnOutset xs={4} padding={0}>
          <TenantCard
            tenant={tenant}
            meter={meter}
            callback_edittenant={click__edit_tenant} //
            callback_editmeter={click__edit_meter} //
            callback_toggleMeter={click__edit_meterActive} //
          />
        </ColumnOutset>
        <ColumnInset xs={4} padding={0}></ColumnInset>
      </Row>

      <Row>
        <ColumnHeading xs={12}>
          <CardTypography variant={"h4"}>Fees</CardTypography>
        </ColumnHeading>
        <ColumnInset xs={4}>
          <CustomerManagementFee tenant_uuid={tenant_uuid} management_fee={management_fee} callback_editManagementFee={click__edit_managementFee} />
        </ColumnInset>
        <ColumnOutset xs={4}>
          <CustomerFixedFee tenant_uuid={tenant_uuid} fixed_fee={fixed_fee} callback_editFixedFee={click__edit_fixedFee} />
        </ColumnOutset>
        <ColumnInset xs={4}>
          <CustomerArrearsFee tenant_uuid={tenant_uuid} arrears_fee={arrears_fee} callback_editArrearsFee={click__edit_arrearsFee} />
        </ColumnInset>
      </Row>

      <HorizontalDivider width="100%" margin={58} />
    </Container>
  );
}

export default TenantManagement;
