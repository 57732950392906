// src/Routes.jsx
import React, { Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import WebsiteLayout from "./layout/WebsiteLayout";
import WebsitePage from "./pages/website/website/WebsitePage";
import RechargePage from "./pages/website/recharge/RechargePage";
import RegisterPage from "./pages/website/register/RegisterPage";
import GetInTouch from "./pages/website/website/Section4";
import TermsAndConditions from "./components/specific/TermsAndConditions";

import Complete_registration from "./pages/customer/complete_registration/Complete_registration";
import Verify_registration from "./pages/admin/verify_registration/Verify_registration";
//services:
// import UtilitySubMetering from "./pages/website/services/UtilitySubMetering/UtilitySubMetering";
import UtilitySubMeteringWrapper from "./pages/website/services/UtilitySubMetering/UtilitySubMeteringWrapper";
import PropertyOwners from "./pages/website/services/UtilitySubMetering/PropertyOwners";
import PropertyOwnersMobile from "./pages/website/services/UtilitySubMetering/PropertyOwnersMobile";
import Complexes from "./pages/website/services/UtilitySubMetering/Complexes";
import ComplexesMobile from "./pages/website/services/UtilitySubMetering/ComplexesMobile";
import Developers from "./pages/website/services/UtilitySubMetering/Developers";
import DevelopersMobile from "./pages/website/services/UtilitySubMetering/DevelopersMobile";

import VendingSolutionWrapper from "./pages/website/services/VendingSolution/VendingSolutionWrapper";

import BulkMeteringWrapper from "./pages/website/services/BulkMetering/BulkMeteringWrapper";

import RechargeWrapper from "./pages/website/services/Recharge/RechargeWrapper";

// import BookingWrapper from "./pages/website/booking/BookingWrapper";

//products:
import Connectivity from "./pages/website/products/Connectivity";
import SuppliersWrapper from "./pages/website/products/SuppliersWrapper";
import Hexing from "./pages/website/products/Hexing";
import Landis from "./pages/website/products/Landis";
import Laison from "./pages/website/products/Laison";
import Conlog from "./pages/website/products/Conlog";
import HexingMobile from "./pages/website/products/HexingMobile";
import LandisMobile from "./pages/website/products/LandisMobile";
import LaisonMobile from "./pages/website/products/LaisonMobile";
import ConlogMobile from "./pages/website/products/ConlogMobile";
//debug

//Landlord/Customer
import CustomerLayout from "./layout/CustomerLayout";
import CustomerPage from "./pages/customer/CustomerPage";
// customer pages
import LandlordManagement from "./pages/customer/landlordManagement/LandlordManagement";
import CustomerReports from "./pages/customer/reports/CustomerReports";

//Admin
import AdminLayout from "./layout/AdminLayout";
import AdminPage from "./pages/admin/AdminPage";
// admin pages
import CustomerManagement from "./pages/admin/customer_management/CustomerManagement";
import ChangeLogManagement from "./pages/admin/changeLogManagement/ChangeLogManagement";
import TariffManagement from "./pages/admin/tariff_management/TariffManagement";
import WebsiteManagement from "./pages/admin/website_management/WebsiteManagement";
import AdminReports from "./pages/admin/reports/AdminReports";


import ProtectedRoute from "./components/common/ProtectedRoute";

import { useSelector } from "react-redux";

function AppRoutes() {
  const auth = useSelector((state) => state.auth);

  return (
    <Routes>
      {/* Public Routes - Website */}
      <Route element={<WebsiteLayout />}>
        <Route path="/" element={<WebsitePage />} />
        <Route path="/pricing" element={<WebsitePage />} />
        <Route path="/get-in-touch" element={<WebsitePage />} />
        <Route path="/recharge" element={<RechargePage />} />
        {/* <Route path="/booking" element={<BookingWrapper />} /> */}
        <Route path="/services/utility-sub-metering" element={<UtilitySubMeteringWrapper />} />
        <Route path="/services/property-owners" element={<PropertyOwners />} />
        <Route path="/services/property-owners-mobile" element={<PropertyOwnersMobile />} />
        <Route path="/services/complexes" element={<Complexes />} />
        <Route path="/services/complexes-mobile" element={<ComplexesMobile />} />
        <Route path="/services/developers" element={<Developers />} />
        <Route path="/services/developers-mobile" element={<DevelopersMobile />} />
        <Route path="/services/vending-solution" element={<VendingSolutionWrapper />} />
        <Route path="/services/bulk-metering" element={<BulkMeteringWrapper />} />
        <Route path="/services/recharge" element={<RechargeWrapper />} />
        <Route path="/products/suppliers" element={<SuppliersWrapper />} />
        <Route path="/supplier/hexing" element={<Hexing />} />
        <Route path="/supplier/conlog" element={<Conlog />} />
        <Route path="/supplier/landis" element={<Landis />} />
        <Route path="/supplier/laison" element={<Laison />} />
        <Route path="/supplier/hexing-mobile" element={<HexingMobile />} />
        <Route path="/supplier/conlog-mobile" element={<ConlogMobile />} />
        <Route path="/supplier/landis-mobile" element={<LandisMobile />} />
        <Route path="/supplier/laison-mobile" element={<LaisonMobile />} />
        <Route path="/products/connectivity" element={<Connectivity />} />
        <Route path="/contact-us" element={<GetInTouch />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/complete-registration" element={<Complete_registration />} />
        <Route path="/verify-registration" element={<Verify_registration />} /> {/* admin/verify_registration */}
      </Route>

      {/* Customer Routes */}
      <Route element={<ProtectedRoute allowedRoles={["customer"]} />}>
        <Route path="/customer/*" element={<CustomerLayout />}>
          <Route index element={<CustomerPage />} />
          <Route path="landlord-management" element={<LandlordManagement />} />
          <Route path="customer-reports" element={<CustomerReports />} />
        </Route>
      </Route>

      {/* Admin Routes */}
      <Route element={<ProtectedRoute allowedRoles={["admin"]} />}>
        <Route path="/admin/*" element={<AdminLayout />}>
          <Route index element={<AdminPage />} />
          <Route path="customer-management" element={<CustomerManagement />} />
          <Route path="change-log-management" element={<ChangeLogManagement />} />
          <Route path="tariff-management" element={<TariffManagement />} />
          <Route path="website-management" element={<WebsiteManagement />} />
          <Route path="reports" element={<AdminReports />} />
        </Route>
      </Route>

      {/* Redirect after login */}
      <Route path="/login-success" element={auth.role === "admin" ? <Navigate to="/admin" /> : auth.role === "customer" ? <Navigate to="/customer" /> : <Navigate to="/" />} />
    </Routes>
  );
}

export default AppRoutes;
