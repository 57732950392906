// src/pages/admin/customer_management/LandlordManagement.jsx
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchLandlordDetails } from "../../../redux/slices/landlordManagementSlice";

import { TableBody, TableCell, TableHead, TableRow, Button, Box } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Container from "../../../components/Grid/Container";
import Row from "../../../components/Grid/Row";
import Column from "../../../components/Grid/Column";

import ColumnInset from "../../../components/Grid/ColumnInset";
import ColumnOutset from "../../../components/Grid/ColumnOutset";
import ColumnHeading from "../../../components/Grid/ColumnHeading";

import Table from "../../../components/table/Table";
import TableButton from "../../../components/table/TableButton";
import TableButtonGroup from "../../../components/table/TableButtonGroup";

import LandlordCard from "../../../components/cards/LandlordCard";
import RepresentativeCard from "../../../components/cards/RepresentativeCard";
import CommunicationCard from "../../../components/cards/CommunicationCard";

import CardTypography from "../../../components/Typography/CardTypography";
import Chip from "../../../components/misc/Chip";
import HorizontalDivider from "../../../components/misc/HorizontalDivider";

import ComplexManagement from "../complexManagement/ComplexManagement";
import TransactionManagement from "../complexManagement/TransactionManagement";

import EditLandlord from "../edit/EditLandlord";
import EditBank from "../edit/EditBank";
import EditDocuments from "../edit/EditDocuments";
import EditCommunication from "../edit/EditCommunication";
import EditRepresentative from "../edit/EditRepresentative";
import EditComplex from "../edit/EditComplex";

import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

function LandlordManagement({ landlord, onBack, toggleView }) {
  const dispatch = useDispatch();
  const customer_uuid = landlord.customer_uuid; //useSelector((state) => state.auth.customer_uuid);
  const [currentView, setCurrentView] = useState("landlordManagement");

  //API `/landlordManagement-onload`
  //*******************************************
  const {
    complexes,
    customer,
    customer_documents,
    customer_representative,
    customer_communication,
    loading,
    error, //
  } = useSelector((state) => state.landlordSlice);
  useEffect(() => {
    if (customer_uuid) {
      dispatch(fetchLandlordDetails(customer_uuid));
    }
  }, [customer_uuid, currentView, dispatch]);
  //*******************************************

  //*******************************************
  //view clicked
  const [selectedComplex, setSelectedComplex] = useState(null);
  const click__view_complex = ({ complex }) => {
    setSelectedComplex(complex);
    setCurrentView("ComplexManagement");
  };
  //*******************************************

  //*******************************************
  //edit clicked
  const click__edit_complex = ({ complex_uuid }) => {
    setCurrentView(() => (
      <EditComplex
        complex_uuid={complex_uuid}
        onBack={() => {
          dispatch(fetchLandlordDetails(customer_uuid));
          setCurrentView("landlordManagement");
        }}
      />
    ));
  };
  const click__edit_landlord = ({ customer_uuid }) => {
    setCurrentView(() => <EditLandlord customer_uuid={customer_uuid} onBack={() => setCurrentView("landlordManagement")} heading="back to landlord management" />);
  };
  const click__edit_bank = ({ customer_uuid }) => {
    setCurrentView(() => <EditBank customer_uuid={customer_uuid} onBack={() => setCurrentView("landlordManagement")} />);
  };
  const click__edit_documents = ({ customer_uuid }) => {
    setCurrentView(() => <EditDocuments customer_uuid={customer_uuid} onBack={() => setCurrentView("landlordManagement")} />);
  };
  const click__edit_representative = ({ customer_uuid }) => {
    setCurrentView(() => <EditRepresentative customer_uuid={customer_uuid} onBack={() => setCurrentView("landlordManagement")} />);
  };
  const click__edit_communication = ({ customer_uuid }) => {
    setCurrentView(() => <EditCommunication customer_uuid={customer_uuid} onBack={() => setCurrentView("landlordManagement")} />);
  };

  const click__edit_landlordActive = async ({ customer_uuid, isLandlordActive }) => {
    alert(`${customer_uuid} : ${isLandlordActive}`);
  };

  //*******************************************

  const toggleComplexView = (target) => {
    if (target === "TransactionManagement") {
      setCurrentView("TransactionManagement");
    } else if (target === "ComplexManagement") {
      setCurrentView("ComplexManagement");
    }
  };

  if (typeof currentView !== "string") {
    return currentView;
  }

  if (currentView === "ComplexManagement") {
    return <ComplexManagement complex_uuid={selectedComplex.complex_uuid} customer_uuid={customer_uuid} onBack={() => setCurrentView("landlordManagement")} toggleView={toggleComplexView} />;
  } else if (currentView === "TransactionManagement") {
    return <TransactionManagement complex_uuid={selectedComplex.complex_uuid} customer_uuid={customer_uuid} onBack={() => setCurrentView("landlordManagement")} toggleView={toggleComplexView} />;
  }

  return (
    <Container>
      <Row>
        <ColumnHeading xs={12} sx={{ display: "flex", flexDirection: "column" }}>
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={onBack}
            variant="contained"
            color="primary"
            sx={{
              position: "fixed",
              bottom: "1px",
              left: "183px",
              width: "400px",
              minWidth: "400px",

              "&:hover": {
                boxShadow: "inset 1px 1px 3px rgba(0,0,0,0.4), inset -1px -1px 3px rgba(255,255,255,0.5)",
              },
              "&:active": {
                boxShadow: "inset 3px 3px 6px rgba(0,0,0,0.4), inset -3px -3px 6px rgba(255,255,255,0.5)",
              },
            }}
          >
            Back to Customer Management
          </Button>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ArrowBackIos onClick={() => toggleView("TransactionManagement")} sx={{ cursor: "pointer", mr: 1 }} />
            <CardTypography variant="h3">Landlord Management</CardTypography>
            <ArrowForwardIos onClick={() => toggleView("TransactionManagement")} sx={{ cursor: "pointer", ml: 1 }} />
          </Box>
        </ColumnHeading>
      </Row>

      {/* Row 1 */}
      <Column xs={8} maxHeight={"800px"}>
        <Row>
          <ColumnInset xs={12} maxHeight="350px">
            {/* complex */}
            <Table maxHeight="200px">
              <TableHead>
                <TableRow>
                  <TableCell>Complex</TableCell>
                  <TableCell>Complex Name</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Units</TableCell>

                  <TableCell>Details</TableCell>
                  <TableCell>Complex Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {complexes.map((row) => (
                  <TableRow key={row.complex_uuid}>
                    <TableCell>{row.complex_uuid}</TableCell>
                    <TableCell>{row.complex_name}</TableCell>
                    <TableCell>
                      {row.complex_address1},{row.complex_address2},{row.complex_postalcode},{row.complex_province}
                    </TableCell>
                    <TableCell>{row.complex_tenants}</TableCell>

                    <TableCell>
                      <TableButtonGroup>
                        <TableButton onClick={() => click__view_complex({ complex: row })}>View</TableButton>
                        <TableButton onClick={() => click__edit_complex({ complex_uuid: row.complex_uuid })}>Edit</TableButton>
                      </TableButtonGroup>
                    </TableCell>

                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Chip label={row.complex_verified ? "Verified" : "Unverified"} verified={row.complex_verified} />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ColumnInset>
        </Row>
        <Row>
          {/* cards */}
          <ColumnInset xs={6}>
            <RepresentativeCard customer_representative={customer_representative} callback_editrepresentative={click__edit_representative} />
          </ColumnInset>
          <ColumnOutset xs={6}>
            <CommunicationCard customer_communication={customer_communication} callback_editcommunication={click__edit_communication} />
          </ColumnOutset>
        </Row>
      </Column>

      {/* landlord card */}
      <Column xs={4}>
        <Row>
          <ColumnOutset xs={12} padding={0}>
            <LandlordCard
              customer={customer}
              customer_documents={customer_documents}
              callback_editlandlord={click__edit_landlord} //
              callback_editbank={click__edit_bank} //
              callback_editdocuments={click__edit_documents} //
              callback_toggleLandlord={click__edit_landlordActive} //
            />
          </ColumnOutset>
        </Row>
      </Column>
    </Container>
  );
}

export default LandlordManagement;
