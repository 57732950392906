import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";

const TableButton = ({ onClick,disabled=false, children  }) => {

  const [isZoom125, setIsZoom125] = useState(false);

  useEffect(() => {
    const checkZoom = () => {
      setIsZoom125(window.devicePixelRatio === 1.25);
    };
    checkZoom();
    window.addEventListener("resize", checkZoom);
    return () => window.removeEventListener("resize", checkZoom);
  }, []);

  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      sx={{
        backgroundColor: "#e0e0e0",
        color: disabled ? "#a1a1a1" : "#333",
        borderRadius: "12px",
        minWidth: "60px",
        padding: isZoom125 ? "2px 2px" : "6px 12px",
        boxShadow: disabled
          ? "none"
          : "4px 4px 8px #bebebe, -4px -4px 8px #ffffff",
        "&:hover": {
          backgroundColor: disabled ? "#d3d3d3" : "#f0f0f0",
          boxShadow: disabled
            ? "none"
            : "inset 2px 2px 5px #bebebe, inset -2px -2px 5px #ffffff",
        },
        textTransform: "capitalize",
        fontSize: isZoom125 ? "0.6rem" : "0.8rem",
      }}
    >
      {children}
    </Button>
  );
};

export default TableButton;
