// src/pages/customer/landlord_management/view/TransactionManagement.jsx
import React, { useEffect, useState } from "react";

import { TableBody, TableCell, TableHead, TableRow, Button, Box } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DownloadIcon from "@mui/icons-material/Download";
import EmailIcon from "@mui/icons-material/Email";

import Container from "../../../components/Grid/Container";
import Row from "../../../components/Grid/Row";
import Column from "../../../components/Grid/Column";

import ColumnInset from "../../../components/Grid/ColumnInset";
import ColumnOutset from "../../../components/Grid/ColumnOutset";
import ColumnHeading from "../../../components/Grid/ColumnHeading";

import Table from "../../../components/table/Table";
import TableButton from "../../../components/table/TableButton";
import TableButtonGroup from "../../../components/table/TableButtonGroup";

import ComplexCard from "../../../components/cards/ComplexCard";
import PayoutCard from "../../../components/cards/PayoutCard";

import SearchSelect from "../../../components/filtering/SearchSelect";
import FilterContainer from "../../../components/filtering/FilterContainer";

import HorizontalDivider from "../../../components/misc/HorizontalDivider";

import CardTypography from "../../../components/Typography/CardTypography";

import { useSelector, useDispatch } from "react-redux";
import { fetchTransactionDetails, downloadStatement, emailStatement } from "../../../redux/slices/complexManagementSlice";
import { update__transaction_received, update__transaction_paid } from "../../../redux/slices/admin";

import FilterDate from "../../../components/filtering/FilterDate";
import CircularProgress from "@mui/material/CircularProgress";

import EmailStatementPopup from "../../../components/misc/EmailStatementPopup";
function TransactionManagement({ complex_uuid,customer_uuid, onBack, toggleView }) {
  const dispatch = useDispatch();
  const [openEmailDialog, setOpenEmailDialog] = useState(false);
  // const customer_uuid = useSelector((state) => state.auth.customer_uuid);
  //*******************************************
  const [filter_date, set__filter_date] = useState(null);
  const [currentView, setCurrentView] = useState("ComplexManagement");
  const {
    transactions,
    payouts,
    loading,
    error, //
  } = useSelector((state) => state.complexSlice);
  useEffect(() => {
    if (complex_uuid) {
      dispatch(fetchTransactionDetails({ complex_uuid, filter_date }));
    }
  }, [complex_uuid, currentView, filter_date, dispatch]);
  //*******************************************
  const onclick__filter_date = (selectedKey) => {
    const { month, year } = selectedKey;
    set__filter_date(`${month}-01-${year}`); //mm-dd-yyyy for javascript interpretoer
  };

  //*******************************************

  const onclick__received = async ({ transaction_number }) => {
    try {
      const result = await dispatch(update__transaction_received({ transaction_number })).unwrap();

      if (result.success) {
        dispatch(fetchTransactionDetails({ filter_date })); // Refresh transactions after update
      }
    } catch (error) {
      console.error("Transaction update failed:", error);
    }
  };

  const onclick__paid = async ({ transaction_number }) => {
    try {
      const result = await dispatch(update__transaction_paid({ transaction_number })).unwrap();

      if (result.success) {
        dispatch(fetchTransactionDetails({ filter_date })); // Refresh transactions after update
      }
    } catch (error) {
      console.error("Transaction update failed:", error);
    }
  };

  const onclick__download_statement = async () => {
    try {
      const pdfBlob = await dispatch(downloadStatement({ transactions })).unwrap();
      const blob = new Blob([pdfBlob], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "statement.pdf";
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Transaction update failed:", error);
    }
  };
  const onclick__email_statement = async (data) => {
    try {
      await dispatch(emailStatement({ transactions,customer_uuid, ...data })).unwrap();
      setOpenEmailDialog(false);
    } catch (error) {
      console.error("Email statement failed:", error);
    }
  };

  //*******************************************
  if (typeof currentView !== "string") {
    return currentView;
  }

  return (
    <>
    <Container>
      <Row>
        <ColumnHeading xs={12} sx={{ display: "flex", flexDirection: "column" }}>
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={onBack}
            variant="contained"
            color="primary"
            sx={{
              position: "fixed",

              bottom: "1px",
              left: "183px",
              width: "400px",
              minWidth: "400px",

              "&:hover": {
                boxShadow: "inset 1px 1px 3px rgba(0,0,0,0.4), inset -1px -1px 3px rgba(255,255,255,0.5)",
              },
              "&:active": {
                boxShadow: "inset 3px 3px 6px rgba(0,0,0,0.4), inset -3px -3px 6px rgba(255,255,255,0.5)",
              },
            }}
          >
            Back to Landlord Management
          </Button>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ArrowBackIos onClick={() => toggleView("ComplexManagement")} sx={{ cursor: "pointer", mr: 1 }} />
            <CardTypography variant="h3">Transaction Management</CardTypography>
            <ArrowForwardIos onClick={() => toggleView("ComplexManagement")} sx={{ cursor: "pointer", ml: 1 }} />
          </Box>
        </ColumnHeading>
      </Row>

      {/* filters */}
      <Column xs={12} maxHeight={"800px"}>
        <Row spacing={0}>
          {/* <FilterContainer xs={4}>
            <SearchSelect
              type="dropdown"
              label="Filter by Tenant"
              data={[
                { key: "Tenant1", value: "8528521" },
                { key: "Tenant2", value: "18252" },
                { key: "Tenant3", value: "963287413" },
              ]}
              onFilter={handleDropdownFilter}
            />
            <SearchSelect
              type="search"
              label="Search"
              data={null} // Not required for search
              onFilter={handleSearchFilter}
            />
          </FilterContainer>

          <FilterContainer xs={4}>
            <SearchSelect
              type="dropdown"
              label="Filter by Meter"
              data={[
                { key: "Meter1", value: "8528521" },
                { key: "cMeter2", value: "18252" },
                { key: "Meter3", value: "963287413" },
              ]}
              onFilter={handleDropdownFilter}
            />
            <SearchSelect
              type="search"
              label="Search"
              data={null} // Not required for search
              onFilter={handleSearchFilter}
            />
          </FilterContainer> */}

          <FilterContainer xs={4}>
            <FilterDate onFilter={onclick__filter_date} />
          </FilterContainer>

          <FilterContainer xs={2}></FilterContainer>

          <FilterContainer xs={6} justifyContent="flex-end">
            <Button
              startIcon={<DownloadIcon />}
              onClick={() => onclick__download_statement()}
              disabled={loading}
              sx={{
                background: "#e0e0e0",
                color: "#333",
                boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.5)",
                "&:hover": {
                  background: "#f0f0f0",
                  boxShadow: "inset 2px 2px 5px rgba(0, 0, 0, 0.1), inset -2px -2px 5px rgba(255, 255, 255, 0.5)",
                },
              }}
              variant="contained"
            >
              {loading ? "Downloading..." : "Download Statement"}
            </Button>
            <Button
                startIcon={<EmailIcon />}
                onClick={() => setOpenEmailDialog(true)}
                disabled={loading}
                sx={{
                  background: "#e0e0e0",
                  color: "#333",
                  boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.5)",
                  "&:hover": {
                    background: "#f0f0f0",
                    boxShadow: "inset 2px 2px 5px rgba(0, 0, 0, 0.1), inset -2px -2px 5px rgba(255, 255, 255, 0.5)",
                  },
                }}
                variant="contained"
              >
                {loading ? "Emailing..." : "Email Statement"}
              </Button>
          </FilterContainer>
        </Row>

        <Row>
          <ColumnInset xs={12} maxHeight="400px">
            <Table maxHeight="400px">
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Time</TableCell>
                  <TableCell>Meter </TableCell>
                  <TableCell>Unit </TableCell>
                  <TableCell>Customer</TableCell>
                  <TableCell>transaction </TableCell>
                  <TableCell>Account</TableCell>
                  <TableCell>Total</TableCell>
                  <TableCell>Sale Amnt</TableCell>
                  <TableCell>Sale VAT</TableCell>

                  <TableCell>Arrears</TableCell>
                  <TableCell>Service</TableCell>
                  <TableCell>Step</TableCell>
                  <TableCell>Token</TableCell>

                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions.map((row, index) => (
                  <TableRow key={row.transaction_number}>
                    <TableCell>{row.transaction_date}</TableCell>
                    <TableCell>{row.transaction_time} </TableCell>
                    <TableCell>{row.meter_number} </TableCell>
                    <TableCell>{row.tenant_unit} </TableCell>
                    <TableCell>{row.customer_name} </TableCell>
                    <TableCell>{row.transaction_number}</TableCell>
                    <TableCell>{row.account_number}</TableCell>
                    <TableCell>{row.total_amount}</TableCell>
                    <TableCell>{row.sale_amount}</TableCell>
                    <TableCell>{row.sale_vat}</TableCell>

                    <TableCell>{row.arrears_amount}</TableCell>
                    <TableCell>{row.service_charge}</TableCell>
                    <TableCell>{row.step_charge}</TableCell>

                    <TableCell>{row.token}</TableCell>

                    <TableCell>
                      <TableButtonGroup>
                        <TableButton onClick={() => onclick__received({ transaction_number: row.transaction_number })} disabled={row.received ? true : false}>
                          RECEIVED
                        </TableButton>
                        <TableButton onClick={() => onclick__paid({ transaction_number: row.transaction_number })} disabled={row.paid ? true : false}>
                          PAID
                        </TableButton>
                      </TableButtonGroup>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ColumnInset>
        </Row>
      </Column>

      <HorizontalDivider width="100%" margin={58} />

      <Row>
        <ColumnHeading xs={12} paddingBottom={3}>
          <CardTypography variant={"h4"}>Payouts</CardTypography>
        </ColumnHeading>
      </Row>
      <Row>
        <ColumnInset xs={12} maxHeight="200px">
          <Table maxHeight="200px">
            <TableHead>
              <TableRow>
                <TableCell>Third Party Fee</TableCell>
                <TableCell>Internal Fee</TableCell>
                <TableCell>Customer Fee </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{payouts.thirdPartyFee}</TableCell>
                <TableCell>{payouts.internalFee} </TableCell>
                <TableCell>{payouts.landlordFee} </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </ColumnInset>
      </Row>
    </Container>

    <EmailStatementPopup open={openEmailDialog} onClose={() => setOpenEmailDialog(false)} onSubmit={onclick__email_statement} />
   
    </>
  );
}

export default TransactionManagement;
