import React from "react";
import { FormControlLabel, Switch, Typography } from "@mui/material";

const CustomSwitch = ({ labelWhenOff, labelWhenOn, checked, onChange }) => {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={checked}
          onChange={onChange}
          sx={{
            "& .MuiSwitch-switchBase.Mui-checked": {
              color: "green",
            },
            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
              backgroundColor: "green",
            },
            "& .MuiSwitch-switchBase": {
              color: "red",
            },
            "& .MuiSwitch-track": {
              backgroundColor: checked ? "green" : "red",
            },
          }}
        />
      }
      label={
        <Typography sx={{ color: "#333", fontWeight: "bold" }}>
          {checked ? labelWhenOn : labelWhenOff}
        </Typography>
      }
    />
  );
};

export default CustomSwitch;
