// src/pages/customer/landlord_management/view/ComplexManagement.jsx
import React, { useEffect, useState } from "react";

import { Typography } from "@mui/material";
import { TableBody, TableCell, TableHead, TableRow, Button, Box } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Container from "../../../components/Grid/Container";
import Row from "../../../components/Grid/Row";
import Column from "../../../components/Grid/Column";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import ColumnInset from "../../../components/Grid/ColumnInset";
import ColumnOutset from "../../../components/Grid/ColumnOutset";
import ColumnHeading from "../../../components/Grid/ColumnHeading";

import Table from "../../../components/table/Table";
import TableButton from "../../../components/table/TableButton";
import TableButtonGroup from "../../../components/table/TableButtonGroup";

import ComplexCard from "../../../components/cards/ComplexCard";

import SearchSelect from "../../../components/filtering/SearchSelect";
import FilterContainer from "../../../components/filtering/FilterContainer";

import UploadFile from "../../../components/buttons/UploadFile";
import BoxButton from "../../../components/buttons/BoxButton";
import IconButton from "../../../components/buttons/IconButton";

import HorizontalDivider from "../../../components/misc/HorizontalDivider";
import Chip from "../../../components/misc/Chip";

import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import AddIcon from "@mui/icons-material/Add";

import CardTypography from "../../../components/Typography/CardTypography";

import CaptureTenant from "./CaptureTenant";
import TenantManagement from "../tenantManagement/TenantManagement";
import TenantTransactions from "../tenantManagement/TenantTransactions";

import EditComplex from "../edit/EditComplex";
import EditTenant from "../edit/EditTenant";

import { useSelector, useDispatch } from "react-redux";
import { fetchComplexDetails } from "../../../redux/slices/complexManagementSlice";

function ComplexManagement({ complex_uuid, onBack, toggleView }) {
  const dispatch = useDispatch();
  // const customer_uuid = useSelector((state) => state.auth.customer_uuid);
  const [currentView, setCurrentView] = useState("ComplexManagement");
  
  //API `/landlordManagement-onload`
  //*******************************************
  const {
    complex,
    tenants,
    card__meter_details,
    loading,
    error, //
  } = useSelector((state) => state.complexSlice);
  useEffect(() => {
    if (complex_uuid) {
      dispatch(fetchComplexDetails(complex_uuid));
    }
  }, [complex_uuid, currentView, dispatch]);
  //*******************************************

  const handleDropdownFilter = (selectedKey) => {
    console.log("Dropdown filter triggered:", selectedKey);
    // Perform filtering or API call based on selectedKey
  };
  const handleSearchFilter = (inputValue) => {
    console.log("Search filter triggered:", inputValue);
    // Perform search or API call based on inputValue
  };

  //*******************************************
  //add tenant clicked
  const click__capture_Tenant = () => {
    //setCurrentView("CaptureTenant");
    setCurrentView(() => <CaptureTenant complex={complex} onBack={() => setCurrentView("ComplexManagement")} />);
  };
  //*******************************************

  //upload tenants csv:
  const [UploadFile_tenants, set__UploadFile_tenants] = useState(true);
  const click__UploadFile_tenants = (file) => {
    console.log("Uploaded file:", file);
    // Add your file processing logic here
  };
  const click__downloadFile_tenants = (file) => {
    set__UploadFile_tenants(false);
    console.log("Uploaded file:", file);
    // Add your file processing logic here
  };

  //*******************************************
  //view clicked
  const [selectedTenant, setSelectedTenant] =  useState(null);
  const click__view_Tenant = ({ tenant }) => {
    setSelectedTenant(tenant);
    setCurrentView("TenantManagement");
    //setCurrentView(() => <TenantManagement tenant_uuid={tenant.tenant_uuid} complex={complex} onBack={() => setCurrentView("ComplexManagement")} />);
  };
  //*******************************************

  //edit clicked
  const click__edit_complex = ({ complex_uuid }) => {
    setCurrentView(() => <EditComplex complex_uuid={complex_uuid} onBack={() => setCurrentView("ComplexManagement")} heading={"Back to Complex Management"} />);
  };
  const click__edit_tenant = ({ tenant_uuid }) => {
    setCurrentView(() => <EditTenant tenant_uuid={tenant_uuid} onBack={() => setCurrentView("ComplexManagement")} heading={"Back to Complex Management"} />);
  };
  //*******************************************

  const toggle_tenantView = (target) => {
    if (target === "TenantTransactions") {
      setCurrentView("TenantTransactions");
    } else if (target === "TenantManagement") {
      setCurrentView("TenantManagement");
    }
  };

  if (typeof currentView !== "string") {
    return currentView;
  }

  if (currentView === "CaptureTenant") {
    return <CaptureTenant complex={complex} onBack={() => setCurrentView("ComplexManagement")} />;
  }
  else if (currentView === "TenantManagement") {
    return <TenantManagement tenant_uuid={selectedTenant.tenant_uuid} complex={complex} onBack={() => setCurrentView("ComplexManagement")} toggleView={toggle_tenantView}/>;
  }
  else if (currentView === "TenantTransactions") {
    return <TenantTransactions tenant_uuid={selectedTenant.tenant_uuid} complex={complex} onBack={() => setCurrentView("ComplexManagement")} toggleView={toggle_tenantView}/>;
  }

  return (
    <Container>
      <Row>
        <ColumnHeading xs={12} sx={{ display: "flex", flexDirection: "column" }}>
          <Button
          startIcon={<ArrowBackIcon />}
            onClick={onBack}
            variant="contained"
            color="primary"
            sx={{
              position: "fixed",

              bottom: "1px",
              left: "183px",
              width:"400px",
              minWidth:"400px",

              "&:hover": {
                boxShadow: "inset 1px 1px 3px rgba(0,0,0,0.4), inset -1px -1px 3px rgba(255,255,255,0.5)",
              },
              "&:active": {
                boxShadow: "inset 3px 3px 6px rgba(0,0,0,0.4), inset -3px -3px 6px rgba(255,255,255,0.5)",
              },
            }}
          >
            Back to Landlord Management
          </Button>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ArrowBackIos onClick={() => toggleView("ComplexTransactions")} sx={{ cursor: "pointer", mr: 1 }} />
            <CardTypography variant="h3">Complex Management</CardTypography>
            <ArrowForwardIos onClick={() => toggleView("ComplexTransactions")} sx={{ cursor: "pointer", ml: 1 }} />
          </Box>
        </ColumnHeading>
      </Row>

      {/* filters */}
      <Column xs={9} maxHeight={"800px"}>
        <Row spacing={0}>
          <FilterContainer xs={6}>
            <SearchSelect
              type="dropdown"
              label="Filter by Tenant"
              data={[
                { key: "Tenant1", value: "8528521" },
                { key: "Tenant2", value: "18252" },
                { key: "Tenant3", value: "963287413" },
              ]}
              onFilter={handleDropdownFilter}
            />
            <SearchSelect
              type="search"
              label="Search"
              data={null} // Not required for search
              onFilter={handleSearchFilter}
            />
          </FilterContainer>

          <FilterContainer xs={6}>
            <SearchSelect
              type="dropdown"
              label="Filter by Meter"
              data={[
                { key: "Meter1", value: "8528521" },
                { key: "cMeter2", value: "18252" },
                { key: "Meter3", value: "963287413" },
              ]}
              onFilter={handleDropdownFilter}
            />
            <SearchSelect
              type="search"
              label="Search"
              data={null} // Not required for search
              onFilter={handleSearchFilter}
            />
          </FilterContainer>
        </Row>

        <Row>
          <ColumnInset xs={12} maxHeight="400px">
            <Table maxHeight="400px">
              <TableHead>
                <TableRow>
                  <TableCell>Unit</TableCell>
                  <TableCell>Tenant Name</TableCell>
                  <TableCell>Tenant ID</TableCell>
                  <TableCell>Tenant Email</TableCell>
                  <TableCell>Tenant Cell</TableCell>
                  <TableCell>Meter No</TableCell>

                  <TableCell>Details</TableCell>
                  <TableCell>Tenant Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tenants.map((row, index) => (
                  <TableRow key={row.tenant_uuid || row.tenant_unit}>
                    <TableCell>{row.tenant_unit}</TableCell>
                    <TableCell>
                      {row.tenant_name} {row.tenant_sname}
                    </TableCell>
                    <TableCell>{row.tenant_id}</TableCell>
                    <TableCell>{row.tenant_email}</TableCell>
                    <TableCell>{row.tenant_cell}</TableCell>
                    <TableCell>{row.meter_number || "N/A"}</TableCell>

                    <TableCell>
                      <TableButtonGroup>
                        <TableButton onClick={() => click__view_Tenant({ tenant: row })}>View</TableButton>
                        <TableButton onClick={() => click__edit_tenant({ tenant_uuid: row.tenant_uuid })}>Edit</TableButton>
                      </TableButtonGroup>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Chip label={row.tenant_verified ? "Verified" : "Unverified"} verified={row.tenant_verified} />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ColumnInset>
        </Row>

        <Row spacing={0.5}>
          <ColumnInset xs={3} alignItems="center" paddingBottom={0} padding={0} mr={2}>
            <CardTypography variant={"h5"}>Add Tenant</CardTypography>
          </ColumnInset>
          <ColumnInset xs={3} alignItems="center" paddingBottom={0} padding={0}>
            <IconButton icon={<AddIcon />} tooltip="Add a new tenant" callback={click__capture_Tenant} />
          </ColumnInset>
        </Row>
      </Column>

      {/* landlord card */}
      <Column xs={3}>
        <Row>
          <ColumnOutset xs={12} padding={0}>
            <ComplexCard complex={complex} card__meter_details={card__meter_details} callback_editcomplex={click__edit_complex} />
          </ColumnOutset>
        </Row>
      </Column>

      <HorizontalDivider width="100%" margin={58} />

      <Row>
        <ColumnHeading xs={12} paddingBottom={3}>
          <CardTypography variant={"h4"}>Import Tenants</CardTypography>
        </ColumnHeading>
      </Row>

      <Row>
        <ColumnOutset xs={6}>
          <BoxButton
            label="Download Tenants Template File"
            buttonText="Download"
            tooltip="Click to save your changes"
            disabled={false}
            optional_tooltip="Provides template csv for you to populate & upload"
            icon={<CloudDownloadIcon />}
            onClick={click__downloadFile_tenants} //
          />
        </ColumnOutset>
        <ColumnInset xs={6}>
          <UploadFile
            label={"Upload Tenants"}
            tooltip={"Download the tenants template and upload here"}
            callback={click__UploadFile_tenants}
            disabled={UploadFile_tenants}
            disabled_tooltip={"Uploading is disabled untill you download the tenants template."}
            enabled_tooltip={"Populate the Template file and upload here"} //
          />
        </ColumnInset>
      </Row>
    </Container>
  );
}

export default ComplexManagement;
