import React, { useEffect } from "react";
import { Box, Divider } from "@mui/material";
import CardTypography from "../Typography/CardTypography";
import Chip from "../misc/Chip";

const PayoutCard = ({ complex, paymentInfo }) => {
  const neumorphicStyles = {
    boxShadow: "7px 7px 15px rgba(0, 0, 0, 0.2), -7px -7px 15px rgba(255, 255, 255, 0.5)",
    borderRadius: "15px",
    background: "#e0e0e0",
    padding: "20px",
    margin: "20px 0",
  };

  useEffect(() => {}, [paymentInfo]);

  return (
    <Box sx={{ maxWidth: 600, margin: "auto", ...neumorphicStyles }}>
      <CardTypography variant="h4">Complex Details</CardTypography>
      <Divider sx={{ marginY: 1 }} />
      <Box>
        <CardTypography>Complex: {complex.complex_uuid}</CardTypography>
        <CardTypography>Name: {complex.complex_name}</CardTypography>
        <CardTypography>Address 1: {complex.complex_address1}</CardTypography>
        <CardTypography>Address 2: {complex.complex_address2}</CardTypography>
        <CardTypography>Postal Code: {complex.complex_postalcode}</CardTypography>
        <CardTypography>Province: {complex.complex_province}</CardTypography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Chip label={complex.complex_verified ? "Verified" : "Unverified"} verified={complex.complex_verified} />
        </Box>
      </Box>
      <CardTypography variant="h4" sx={{ marginTop: 4 }}>
        Payment Info
      </CardTypography>
      <Divider sx={{ marginY: 1 }} />
      <Box>
        <CardTypography>3rd Party Fee: {paymentInfo.thirdPartyFee}</CardTypography>
        <CardTypography>MeterMinds Fee: {paymentInfo.metermindsFee}</CardTypography>
        <CardTypography>Landlord Fees: {paymentInfo.landlordFee}</CardTypography>
      </Box>
    </Box>
  );
};

export default PayoutCard;
