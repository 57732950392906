//redux/slices/landlord_login/tenantSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"; // Import createSlice and createAsyncThunk
import axios from "axios";
import { toast } from "react-toastify";
import { initialState } from "../initialState";

export const fetchTenantManagement = createAsyncThunk(
  "tenantManagementSlice/fetchTenantManagement", //
  async ({ tenant_uuid, complex, filter_date }, { rejectWithValue }) => {
    //
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/tenantManagement/tenantManagement-onload`, { tenant_uuid, complex, filter_date });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to fetch details");
    }
  }
);

export const fetchManagementFee = createAsyncThunk(
  "tenantManagementSlice/fetchManagementFee", //
  async ({ tenant_uuid }, { rejectWithValue }) => {
    //
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/tenantManagement/get-management-fee`, { tenant_uuid });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to fetch details");
    }
  }
);
export const fetchFixedFee = createAsyncThunk(
  "tenantManagementSlice/fetchFixedFee", //
  async ({ tenant_uuid }, { rejectWithValue }) => {
    //
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/tenantManagement/get-fixed-fee`, { tenant_uuid });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to fetch details");
    }
  }
);
export const fetchCustomerArrearsFee = createAsyncThunk(
  "tenantManagementSlice/fetchCustomerArrearsFee", //
  async ({ tenant_uuid }, { rejectWithValue }) => {
    //
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/tenantManagement/get-arrears-fee`, { tenant_uuid });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to fetch details");
    }
  }
);

export const editManagementFee = createAsyncThunk(
  "tenantManagementSlice/editManagementFee", //
  async ({ tenant_uuid, data }, { rejectWithValue }) => {
    //
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/tenantManagement/edit-management-fee`, { tenant_uuid, data });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to fetch details");
    }
  }
);
export const deleteManagementFee = createAsyncThunk(
  "tenantManagementSlice/deleteManagementFee", //
  async ({ tenant_uuid }, { rejectWithValue }) => {
    //
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/tenantManagement/delete-management-fee`, { tenant_uuid });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to fetch details");
    }
  }
);

export const editFixedFee = createAsyncThunk(
  "tenantManagementSlice/editFixedFee", //
  async ({ tenant_uuid, data }, { rejectWithValue }) => {
    //
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/tenantManagement/edit-fixed-fee`, { tenant_uuid, data });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to fetch details");
    }
  }
);
export const deleteFixedFee = createAsyncThunk(
  "tenantManagementSlice/deleteFixedFee", //
  async ({ tenant_uuid }, { rejectWithValue }) => {
    //
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/tenantManagement/delete-fixed-fee`, { tenant_uuid });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to fetch details");
    }
  }
);

export const editArrearsFee = createAsyncThunk(
  "tenantManagementSlice/editArrearsFee", //
  async ({ tenant_uuid, data }, { rejectWithValue }) => {
    //
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/tenantManagement/edit-arrears-fee`, { tenant_uuid, data });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to fetch details");
    }
  }
);
export const deleteArrearsFee = createAsyncThunk(
  "tenantManagementSlice/deleteArrearsFee", //
  async ({ tenant_uuid }, { rejectWithValue }) => {
    //
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/tenantManagement/delete-arrears-fee`, { tenant_uuid });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to fetch details");
    }
  }
);

export const downloadStatement = createAsyncThunk("tenantManagementSlice/downloadStatement", async ({ transactions }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/tenantManagement/download-statment`, { transactions }, { responseType: "blob" });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || "Failed to download statement");
  }
});

export const emailStatement = createAsyncThunk("tenantManagementSlice/emailStatement", async ({ transactions, customer_uuid, landlord, admin, customEmail, subject, body }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/tenantManagement/email-statment`, { transactions, customer_uuid, landlord, admin, customEmail, subject, body });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || "Failed to download statement");
  }
});

const tenantManagementSlice = createSlice({
  name: "tenant",
  initialState: {
    ...initialState,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTenantManagement.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTenantManagement.fulfilled, (state, action) => {
        state.loading = false;
        state.meter = action.payload.meter;
        state.tenant = action.payload.tenant;
        state.transactions = action.payload.transactions;
        state.payouts = action.payload.payouts;
        state.management_fee = action.payload.management_fee;
        state.fixed_fee = action.payload.fixed_fee;
        state.arrears_fee = action.payload.arrears_fee;
        console.log(action.payload);
      })
      .addCase(fetchTenantManagement.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message || "An error occurred";
        toast.error(state.error);
      });

    builder
      .addCase(fetchManagementFee.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchManagementFee.fulfilled, (state, action) => {
        state.loading = false;
        state.management_fee = action.payload.management_fee;
        //console.log(action.payload);
      })
      .addCase(fetchManagementFee.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message || "An error occurred";
        toast.error(state.error);
      });

    builder
      .addCase(fetchFixedFee.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFixedFee.fulfilled, (state, action) => {
        state.loading = false;
        state.fixed_fee = action.payload.fixed_fee;
        //console.log(action.payload);
      })
      .addCase(fetchFixedFee.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message || "An error occurred";
        toast.error(state.error);
      });

    builder
      .addCase(fetchCustomerArrearsFee.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCustomerArrearsFee.fulfilled, (state, action) => {
        state.loading = false;
        state.arrears_fee = action.payload.arrears_fee;
        //console.log(action.payload);
      })
      .addCase(fetchCustomerArrearsFee.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message || "An error occurred";
        toast.error(state.error);
      });

    builder
      .addCase(editManagementFee.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editManagementFee.fulfilled, (state, action) => {
        state.loading = false;
        state.management_fee = action.payload.management_fee;
        //console.log(action.payload);
      })
      .addCase(editManagementFee.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message || "An error occurred";
        toast.error(state.error);
      });

    builder
      .addCase(editFixedFee.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editFixedFee.fulfilled, (state, action) => {
        state.loading = false;
        state.fixed_fee = action.payload.fixed_fee;
        //console.log(action.payload);
      })
      .addCase(editFixedFee.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message || "An error occurred";
        toast.error(state.error);
      });

    builder
      .addCase(editArrearsFee.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editArrearsFee.fulfilled, (state, action) => {
        state.loading = false;
        state.arrears_fee = action.payload.arrears_fee;
        //console.log(action.payload);
      })
      .addCase(editArrearsFee.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message || "An error occurred";
        toast.error(state.error);
      });

    builder
      .addCase(deleteManagementFee.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteManagementFee.fulfilled, (state, action) => {
        state.loading = false;
        state.management_fee = action.payload.management_fee;
        //console.log(action.payload);
      })
      .addCase(deleteManagementFee.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message || "An error occurred";
        toast.error(state.error);
      });

    builder
      .addCase(deleteFixedFee.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteFixedFee.fulfilled, (state, action) => {
        state.loading = false;
        state.fixed_fee = action.payload.fixed_fee;
        //console.log(action.payload);
      })
      .addCase(deleteFixedFee.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message || "An error occurred";
        toast.error(state.error);
      });

    builder
      .addCase(deleteArrearsFee.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteArrearsFee.fulfilled, (state, action) => {
        state.loading = false;
        state.arrears_fee = action.payload.arrears_fee;
        //console.log(action.payload);
      })
      .addCase(deleteArrearsFee.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message || "An error occurred";
        toast.error(state.error);
      });

    builder
      .addCase(downloadStatement.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(downloadStatement.fulfilled, (state, action) => {
        state.loading = false;
        console.log(action.payload);
      })
      .addCase(downloadStatement.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message || "An error occurred";
        toast.error(state.error);
      })

      .addCase(emailStatement.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(emailStatement.fulfilled, (state, action) => {
        state.loading = false;
        toast.success("Email sent successfully!");
      })
      .addCase(emailStatement.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message || "An error occurred";
        toast.error(state.error);
      });
  },
});

export default tenantManagementSlice.reducer;
