//redux/slices/complexManagement.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"; // Import createSlice and createAsyncThunk
import axios from "axios";
import { toast } from "react-toastify";
import { initialState } from "../initialState";

export const captureTenant = createAsyncThunk("complexManagement/captureTenant", async ({ tenant, complex }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/complexManagement/capture-tenant`, { tenant, complex });
    return response.data;
  } catch (error) {
    console.error("API error:", error);
    return rejectWithValue(error.response?.data?.message || "Failed to capture tenant.");
  }
});

export const captureMeter = createAsyncThunk("complexManagement/captureMeter", async ({ meter, complex, tenant }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/complexManagement/capture-meter`, { meter, complex, tenant });
    return response.data;
  } catch (error) {
    console.error("API error:", error);
    return rejectWithValue(error.response?.data?.message || "Failed to capture tenant.");
  }
});

export const fetchComplexDetails = createAsyncThunk("complexManagement/fetchComplexDetails", async (complex_uuid, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/complexManagement/complexManagement-onload`, { complex_uuid });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || "Failed to fetch details");
  }
});
export const fetchTransactionDetails = createAsyncThunk("complexManagement/fetchTransactionDetails", async ({ complex_uuid, filter_date }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/complexManagement/transactionManagement-onload`, { complex_uuid, filter_date });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || "Failed to fetch details");
  }
});

export const downloadStatement = createAsyncThunk("complexManagement/downloadStatement", async ({ transactions }, { rejectWithValue }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/complexManagement/download-statment`,
      { transactions },
      { responseType: "blob" }
    );
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || "Failed to download statement");
  }
});

export const emailStatement = createAsyncThunk("complexManagement/emailStatement",
  async ({ transactions,customer_uuid, landlord, admin, customEmail, subject, body }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/complexManagement/email-statment`,
        { transactions,customer_uuid, landlord, admin, customEmail, subject, body }
      );
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || "Failed to download statement");
  }
});

const complexManagementSlice = createSlice({
  name: "complexManagement",
  initialState: {
    ...initialState,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(captureTenant.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(captureTenant.fulfilled, (state, action) => {
        state.loading = false;
        state.tenants = action.payload.tenants;
        state.card__meter_details = action.payload.card__meter_details;
        toast.success("Tenant captured successfully!");
      })
      .addCase(captureTenant.rejected, (state, action) => {
        console.log(action.payload);
        state.loading = false;
        state.error = action.payload || "An error occurred";

        if (Array.isArray(state.error)) {
          const message = (
            <div>
              {state.error.map((msg, index) => (
                <div key={index}>{msg}</div>
              ))}
            </div>
          );
          toast.error(message);
        } else {
          toast.error(state.error);
        }
      })

      .addCase(captureMeter.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(captureMeter.fulfilled, (state, action) => {
        state.loading = false;
        state.tenants = action.payload.tenants;
        state.card__meter_details = action.payload.card__meter_details;
        toast.success("Tenant captured successfully!");
      })
      .addCase(captureMeter.rejected, (state, action) => {
        console.log(action.payload);
        state.loading = false;
        state.error = action.payload || "An error occurred";

        if (Array.isArray(state.error)) {
          const message = (
            <div>
              {state.error.map((msg, index) => (
                <div key={index}>{msg}</div>
              ))}
            </div>
          );
          toast.error(message);
        } else {
          toast.error(state.error);
        }
      })

      .addCase(fetchComplexDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchComplexDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.tenants = action.payload.tenants;
        state.card__meter_details = action.payload.card__meter_details;
        state.complex = action.payload.complex;
        console.log(action.payload);
      })
      .addCase(fetchComplexDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message || "An error occurred";
        toast.error(state.error);
      })

      .addCase(fetchTransactionDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTransactionDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.tenants = action.payload.tenants;
        state.card__meter_details = action.payload.card__meter_details;
        state.complex = action.payload.complex;
        state.transactions = action.payload.transactions;
        state.payouts = action.payload.payouts;
      })
      .addCase(fetchTransactionDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message || "An error occurred";
        toast.error(state.error);
      })

      .addCase(downloadStatement.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(downloadStatement.fulfilled, (state, action) => {
        state.loading = false;
        console.log(action.payload);
      })
      .addCase(downloadStatement.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message || "An error occurred";
        toast.error(state.error);
      })

      .addCase(emailStatement.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(emailStatement.fulfilled, (state, action) => {
        state.loading = false;
        toast.success("Email sent successfully!");
      })
      .addCase(emailStatement.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message || "An error occurred";
        toast.error(state.error);
      });
  },
});

export default complexManagementSlice.reducer;
