import React, { useState } from "react";
import { Box, Typography, Button, FormControl, InputLabel, Select, MenuItem, TextField, CircularProgress } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { alpha } from "@mui/material/styles";

const NeumorphicForm = styled("form")(({ theme }) => ({
  position: "relative",
  width: "100%",
  maxWidth: "100%",
  boxSizing: "border-box",
  background: "rgba(255,255,255,0.02)",
  backdropFilter: "blur(10px)",
  WebkitBackdropFilter: "blur(10px)",
  border: "1px solid rgba(255,255,255,0.3)",
  boxShadow: "0px 4px 12px rgba(0,0,0,0.2)",
  color: theme.palette.silverShades.light,
  borderRadius: "15px",
  padding: theme.spacing(1),
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    margin: "0 auto",
    padding: theme.spacing(2),
  },
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderRadius: "inherit",
    pointerEvents: "none",
    backgroundImage: `
        url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><polygon points="12,2 15,9 22,9 17,14 19,21 12,17 5,21 7,14 2,9 9,9" fill="rgba(255,255,255,0.9)" filter="drop-shadow(0 0 12px rgba(255,255,255,0.9))"/></svg>'),
        url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><polygon points="12,2 15,9 22,9 17,14 19,21 12,17 5,21 7,14 2,9 9,9" fill="rgba(255,255,255,0.9)" filter="drop-shadow(0 0 12px rgba(255,255,255,0.9))"/></svg>'),
        url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><polygon points="12,2 15,9 22,9 17,14 19,21 12,17 5,21 7,14 2,9 9,9" fill="rgba(255,255,255,0.9)" filter="drop-shadow(0 0 12px rgba(255,255,255,0.9))"/></svg>')
      `,
    backgroundRepeat: "no-repeat",
    backgroundSize: "30px 30px",
    backgroundPosition: "20% 30%, 70% 50%, 50% 80%",
    animation: "sparkle 5s ease-in-out infinite",
  },
  "@keyframes sparkle": {
    "0%": {
      opacity: 0.4,
      transform: "scale(0.9) translate(0, 0)",
    },
    "50%": {
      opacity: 0.6,
      transform: "scale(1) translate(10px, -10px)",
    },
    "100%": {
      opacity: 0.4,
      transform: "scale(0.9) translate(0, 0)",
    },
  },
}));

const SecondaryForm = styled("form")(({ theme }) => ({
  position: "relative",
  width: "100%",
  maxWidth: "100%",
  boxSizing: "border-box",
  background: "rgba(255,255,255,0.01)",
  backdropFilter: "blur(8px)",
  WebkitBackdropFilter: "blur(8px)",
  border: "1px solid rgba(255,255,255,0.05)",
  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
  color: theme.palette.silverShades.dark,
  borderRadius: "15px",
  padding: theme.spacing(1),
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    margin: "0 auto",
    padding: theme.spacing(2),
  },
}));

const pricingData = {
  Electricity: {
    siteInspection: {
      singleCottage: 750,
      complex: 1100,
      commercial: 1400,
    },
    hardwareInstallation: {
      singlePhase: {
        integratedMeter: 1100,
        splitMeter: 1600,
      },
      threePhase: {
        integratedMeter: 2500,
        splitMeter: 2900,
      },
    },
  },
  Water: {
    siteInspection: {
      singleCottage: 800,
      complex: 1300,
      commercial: 1900,
    },
    hardwareInstallation: {
      classCMeter: 2800,
      classBMeter: 3100,
      ultrasonicMeter: 3300,
    },
  },
  Gas: {
    siteInspection: {
      complex: 1600,
      commercial: 1800,
    },
    hardwareInstallation: 3850,
  },
};

function formatKey(key) {
  return key
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase())
    .trim();
}

function PricingEstimateMobile() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [utilityType, setUtilityType] = useState("");
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleNext = () => setActiveStep((prev) => prev + 1);
  const handleBack = () => setActiveStep((prev) => prev - 1);

  const handleSubmit = () => {
    // Simulate successful API call; in a real app, call your API here.
    setIsSubmitting(true);
    setTimeout(() => {
      setIsSubmitting(false);
      handleNext();
    }, 2000);
  };

  return (
    <Box
      sx={{
        width: "100vw",
        minHeight: "100%",
        background: "transparent",
        p: 2,
        boxSizing: "border-box",
        overflowY: "auto",
      }}
    >
      {activeStep === 0 && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <NeumorphicForm>
            <Typography variant="h5" sx={{ textAlign: "center", mb: 2, fontSize: "1.6rem" }}>
              Utility Type
            </Typography>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel
                id="booking-type-label"
                sx={{
                  color: theme.palette.silverShades.light,
                  fontSize: "1.4rem",
                  fontWeight: 200,
                }}
              >
                Select Utility
              </InputLabel>
              <Select
                sx={{
                  fontSize: "1.4rem",
                  color: theme.palette.silverShades.light,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme.palette.silverShades.light,
                    padding: "0 8px",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme.palette.silverShades.light,
                    backgroundColor: "rgba(0,0,0,0.45)",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme.palette.silverShades.light,
                  },
                }}
                labelId="booking-type-label"
                value={utilityType}
                label="Utility Type"
                onChange={(e) => setUtilityType(e.target.value)}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      "& .MuiMenuItem-root": {
                        fontSize: "1.4rem",
                      },
                    },
                  },
                }}
              >
                <MenuItem value="Electricity">Electricity</MenuItem>
                <MenuItem value="Water">Water</MenuItem>
                <MenuItem value="Gas">Gas</MenuItem>
              </Select>
            </FormControl>

            <Button
              variant="contained"
              onClick={handleNext}
              disabled={!utilityType}
              sx={{
                mt: 2,
                fontSize: "1.8rem",
                py: 1,
                px: 4,
                py: theme.spacing(0.5),
                fontSize: "1rem",
                textTransform: "none",
                borderRadius: "8px",
                border: "1px solid white",
                backgroundColor: alpha(theme.palette.blackShades?.black || "#000", 0.05),
                backdropFilter: "blur(6px)",
                color: theme.palette.silverShades?.light || "#ccc",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                transition: "background-color 0.3s, color 0.3s, box-shadow 0.3s",
                "&:hover": {
                  backgroundColor: alpha(theme.palette.blackShades?.black || "#000", 0.5),
                  color: theme.palette.common.white,
                  boxShadow: "1px 1px 16px rgba(255, 255, 255, 1)",
                },
              }}
            >
              Next
            </Button>
          </NeumorphicForm>

          <SecondaryForm>
            <Typography variant="h5" sx={{ textAlign: "center", mb: 2, fontSize: "1.2rem" }}>
              meter-only pricing & monthly fees
            </Typography>
            <Button
              variant="contained"
              onClick={() => {navigate("/get-in-touch")}}
              sx={{
                mt: 2,
                fontSize: "1.8rem",
                py: 1,
                px: 4,
                py: theme.spacing(0.5),
                fontSize: "1rem",
                textTransform: "none",
                borderRadius: "8px",
                border: "0.5px solid rgba(255,255,255,0.2)",
                backgroundColor: alpha(theme.palette.blackShades?.black || "#000", 0.1),
                backdropFilter: "blur(6px)",
                color: theme.palette.silverShades?.dark || "#ccc",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
                transition: "background-color 0.3s, color 0.3s, box-shadow 0.3s",
                "&:hover": {
                  backgroundColor: alpha(theme.palette.blackShades?.black || "#000", 0.5),
                  color: theme.palette.common.white,
                  boxShadow: "1px 1px 16px rgba(255, 255, 255, 1)",
                },
              }}
            >
              Click Here
            </Button>
          </SecondaryForm>
        </Box>
      )}

      {activeStep === 1 && (
        <NeumorphicForm>
          <Typography
            variant="h5"
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              mb: 2,
              fontSize: "1.6rem",
            }}
          >
            {utilityType}
          </Typography>
          {pricingData[utilityType] && (
            <Box sx={{ fontSize: "1.4rem", lineHeight: 1.6, textAlign: "left" }}>
              <Typography sx={{ fontSize: "1.2rem", mb: 1, fontWeight: "bold" }}>Site Inspection</Typography>

              <ul style={{ paddingLeft: "1rem", margin: 0 }}>
                {Object.entries(pricingData[utilityType].siteInspection).map(([key, value]) => (
                  <li key={key}>
                    <Typography sx={{ fontSize: "1rem", mb: 0.5 }}>
                      {formatKey(key)}: R {value}
                    </Typography>
                  </li>
                ))}
              </ul>

              {/* Hardware + Installation */}
              <Typography sx={{ fontSize: "1.2rem", mt: 2, mb: 1, fontWeight: "bold" }}>Hardware + Installation</Typography>
              <ul style={{ paddingLeft: "1rem", margin: 0 }}>
                {typeof pricingData[utilityType].hardwareInstallation === "object" ? (
                  Object.entries(pricingData[utilityType].hardwareInstallation).map(([subKey, subValue]) =>
                    typeof subValue === "object" ? (
                      <li key={subKey} style={{ marginBottom: "0.5rem" }}>
                        <Typography
                          sx={{
                            textTransform: "capitalize",
                            fontWeight: "bold",
                            fontSize: "1.1rem",
                          }}
                        >
                          {formatKey(subKey)}
                        </Typography>
                        <ul style={{ paddingLeft: "1rem", margin: 0 }}>
                          {Object.entries(subValue).map(([meterKey, meterPrice]) => (
                            <li key={meterKey} style={{ marginBottom: "0.5rem" }}>
                              <Typography sx={{ fontSize: "1rem" }}>
                                {formatKey(meterKey)}: R {meterPrice}
                              </Typography>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ) : (
                      <li key={subKey} style={{ marginBottom: "0.5rem" }}>
                        <Typography sx={{ fontSize: "1rem" }}>
                          {formatKey(subKey)}: R {subValue}
                        </Typography>
                      </li>
                    )
                  )
                ) : (
                  <li>
                    <Typography sx={{ fontSize: "1.6rem" }}>R {pricingData[utilityType].hardwareInstallation}</Typography>
                  </li>
                )}
              </ul>

              <Box sx={{ fontSize: "1.4rem", lineHeight: 1.6, textAlign: "center" }}>
                <Button
                  variant="contained"
                  onClick={() => {
                    setActiveStep(0);
                    setUtilityType("");
                  }}
                  sx={{
                    mt: 2,
                    fontSize: "1.8rem",
                    px: 4,
                    py: theme.spacing(0.5),
                    fontSize: "1rem",
                    textTransform: "none",
                    borderRadius: "8px",
                    border: "1px solid white",
                    backgroundColor: alpha(theme.palette.blackShades?.black || "#000", 0.05),
                    backdropFilter: "blur(6px)",
                    color: theme.palette.silverShades?.light || "#ccc",
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                    transition: "background-color 0.3s, color 0.3s, box-shadow 0.3s",
                    "&:hover": {
                      backgroundColor: alpha(theme.palette.blackShades?.black || "#000", 0.5),
                      color: theme.palette.common.white,
                      boxShadow: "1px 1px 16px rgba(255, 255, 255, 1)",
                    },
                  }}
                >
                  Back
                </Button>
              </Box>
            </Box>
          )}
        </NeumorphicForm>
      )}

      <Typography sx={{ textAlign: "center", mt: 5, fontSize: "0.8rem" }}>(Disclaimer: Please note this is an estimate only, full quotation will be provided based on requirements & quantity required)</Typography>
    </Box>
  );
}

export default PricingEstimateMobile;
