import React, { useState,useEffect } from "react";
import { Box, MenuItem, Select, InputLabel, Button } from "@mui/material";

const FilterDate = ({ onFilter }) => {

    const currentDate = new Date();
const defaultMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
const defaultYear = String(currentDate.getFullYear());

  const [selectedMonth, setSelectedMonth] = useState(defaultMonth);
  const [selectedYear, setSelectedYear] = useState(defaultYear);
  const [isButtonVisible, setButtonVisible] = useState(false);




  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
    setButtonVisible(true);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    setButtonVisible(true);
  };

  const handleOkClick = () => {
    if (onFilter) {
      onFilter({ month: selectedMonth, year: selectedYear });
    }
    setButtonVisible(false);
  };

  const commonStyles = {
    boxShadow: "4px 4px 16px #bebebe, -2px -2px 16px #ffffff",
    borderRadius: "12px",
    backgroundColor: "#e0e0e0",
  };
  const compactMenuItemStyles = {
    padding: "2px 6px",
    fontSize: "0.70rem",
  };
  const compactSelectStyles = {
    padding: "0px",
    fontSize: "0.8rem",
    minHeight: "30px",
    lineHeight: "1.2",
    "& .MuiSelect-select": {
      padding: "2px 4px",
    },
  };

  const months = [
    { key: null, value: "all" },
    { key: "01", value: "January" },
    { key: "02", value: "February" },
    { key: "03", value: "March" },
    { key: "04", value: "April" },
    { key: "05", value: "May" },
    { key: "06", value: "June" },
    { key: "07", value: "July" },
    { key: "08", value: "August" },
    { key: "09", value: "September" },
    { key: "10", value: "October" },
    { key: "11", value: "November" },
    { key: "12", value: "December" },
  ];

  const years = [
    { key: null, value: "all" },
    { key: "2025", value: "2025" },
    { key: "2026", value: "2026" },
  ];

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2, marginBottom: "5px" }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <InputLabel sx={{ fontSize: "1rem", marginBottom: "2px" }}>Month</InputLabel>
        <Select value={selectedMonth} onChange={handleMonthChange} displayEmpty sx={{ ...commonStyles, ...compactSelectStyles }}>
          <MenuItem value="" disabled sx={compactMenuItemStyles}>
            Select an option
          </MenuItem>
          {months.map((item, index) => (
            <MenuItem key={index} value={item.key} sx={compactMenuItemStyles}>
              {item.value}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <InputLabel sx={{ fontSize: "1rem", marginBottom: "2px" }}>Year</InputLabel>
        <Select value={selectedYear} onChange={handleYearChange} displayEmpty sx={{ ...commonStyles, ...compactSelectStyles }}>
          <MenuItem value="" disabled sx={compactMenuItemStyles}>
            Select an option
          </MenuItem>
          {years.map((item, index) => (
            <MenuItem key={index} value={item.key} sx={compactMenuItemStyles}>
              {item.value}
            </MenuItem>
          ))}
        </Select>
      </Box>
      {isButtonVisible && (
        <Button
          variant="contained"
          sx={{ ...commonStyles, padding: "5px 10px", fontSize: "0.8rem", textTransform: "capitalize" }}
          onClick={handleOkClick}
        >
          Ok
        </Button>
      )}
    </Box>
  );
};

export default FilterDate;
